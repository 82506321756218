/* tslint:disable */
/* eslint-disable */
/**
 * Fabs API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 100
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AreaDto
 */
export interface AreaDto {
    /**
     * 
     * @type {string}
     * @memberof AreaDto
     */
    'surface': string;
    /**
     * 
     * @type {string}
     * @memberof AreaDto
     */
    'back': string;
}
/**
 * 
 * @export
 * @interface BatchListDto
 */
export interface BatchListDto {
    /**
     * 
     * @type {Array<PositionListDto>}
     * @memberof BatchListDto
     */
    'positionList': Array<PositionListDto>;
}
/**
 * 
 * @export
 * @interface CalcInfoDto
 */
export interface CalcInfoDto {
    /**
     * 
     * @type {OutLineDto}
     * @memberof CalcInfoDto
     */
    'outLine': OutLineDto;
    /**
     * 
     * @type {AreaDto}
     * @memberof CalcInfoDto
     */
    'area': AreaDto;
    /**
     * 
     * @type {VolumeDto}
     * @memberof CalcInfoDto
     */
    'volume': VolumeDto;
    /**
     * 
     * @type {string}
     * @memberof CalcInfoDto
     */
    'createTime': string;
}
/**
 * 
 * @export
 * @interface CalcResultDto
 */
export interface CalcResultDto {
    /**
     * 
     * @type {NormalDto}
     * @memberof CalcResultDto
     */
    'normal': NormalDto;
    /**
     * 
     * @type {ExpressDto}
     * @memberof CalcResultDto
     */
    'express': ExpressDto;
}
/**
 * 
 * @export
 * @interface CameraDto
 */
export interface CameraDto {
    /**
     * 
     * @type {CameraXyzDto}
     * @memberof CameraDto
     */
    'pos'?: CameraXyzDto;
    /**
     * 
     * @type {CameraXyzDto}
     * @memberof CameraDto
     */
    'up'?: CameraXyzDto;
}
/**
 * 
 * @export
 * @interface CameraOutputDto
 */
export interface CameraOutputDto {
    /**
     * 
     * @type {CameraXyzDto}
     * @memberof CameraOutputDto
     */
    'camera_pos'?: CameraXyzDto;
    /**
     * 
     * @type {CameraXyzDto}
     * @memberof CameraOutputDto
     */
    'camera_up'?: CameraXyzDto;
}
/**
 * 
 * @export
 * @interface CameraXyzDto
 */
export interface CameraXyzDto {
    /**
     * 
     * @type {number}
     * @memberof CameraXyzDto
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraXyzDto
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof CameraXyzDto
     */
    'z'?: number;
}
/**
 * 
 * @export
 * @interface DeleteModelNumberDto
 */
export interface DeleteModelNumberDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteModelNumberDto
     */
    'checkResult': Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteModelNumberParam
 */
export interface DeleteModelNumberParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteModelNumberParam
     */
    'modelNumber': Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteTrialCalcDto
 */
export interface DeleteTrialCalcDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteTrialCalcDto
     */
    'checkResult': Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteTrialCalcParam
 */
export interface DeleteTrialCalcParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeleteTrialCalcParam
     */
    'specNumber': Array<string>;
}
/**
 * 
 * @export
 * @interface ExpressDto
 */
export interface ExpressDto {
    /**
     * 
     * @type {number}
     * @memberof ExpressDto
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof ExpressDto
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface GetCanCreateQuotationDto
 */
export interface GetCanCreateQuotationDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCanCreateQuotationDto
     */
    'errorList': Array<string>;
}
/**
 * 
 * @export
 * @interface GetCanCreateQuotationParam
 */
export interface GetCanCreateQuotationParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCanCreateQuotationParam
     */
    'trialCalcList': Array<string>;
}
/**
 * 
 * @export
 * @interface GetChatIdentityHashDto
 */
export interface GetChatIdentityHashDto {
    /**
     * 
     * @type {string}
     * @memberof GetChatIdentityHashDto
     */
    'hash'?: string;
}
/**
 * 
 * @export
 * @interface GetDeletedModelNumbersDetail
 */
export interface GetDeletedModelNumbersDetail {
    /**
     * 
     * @type {string}
     * @memberof GetDeletedModelNumbersDetail
     */
    'modelNumber': string;
    /**
     * 
     * @type {string}
     * @memberof GetDeletedModelNumbersDetail
     */
    'labelName': string;
    /**
     * 
     * @type {string}
     * @memberof GetDeletedModelNumbersDetail
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof GetDeletedModelNumbersDetail
     */
    'threeDDataId': string;
    /**
     * 
     * @type {string}
     * @memberof GetDeletedModelNumbersDetail
     */
    'captureBucketName': string;
    /**
     * 
     * @type {string}
     * @memberof GetDeletedModelNumbersDetail
     */
    'captureSmallKey': string;
    /**
     * 
     * @type {string}
     * @memberof GetDeletedModelNumbersDetail
     */
    'captureLargeKey': string;
}
/**
 * 
 * @export
 * @interface GetDeletedModelNumbersDto
 */
export interface GetDeletedModelNumbersDto {
    /**
     * 
     * @type {Array<GetDeletedModelNumbersDetail>}
     * @memberof GetDeletedModelNumbersDto
     */
    'deletedModelNumbers': Array<GetDeletedModelNumbersDetail>;
}
/**
 * 
 * @export
 * @interface GetDeletedModelNumbersParam
 */
export interface GetDeletedModelNumbersParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetDeletedModelNumbersParam
     */
    'specNumber': Array<string>;
}
/**
 * 
 * @export
 * @interface GetHolidayDto
 */
export interface GetHolidayDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetHolidayDto
     */
    'holidayList': Array<string>;
}
/**
 * 
 * @export
 * @interface GetInformationDto
 */
export interface GetInformationDto {
    /**
     * 
     * @type {Array<InformationDto>}
     * @memberof GetInformationDto
     */
    'informationList': Array<InformationDto>;
}
/**
 * 
 * @export
 * @interface GetMaterialPriceParam
 */
export interface GetMaterialPriceParam {
    /**
     * 
     * @type {string}
     * @memberof GetMaterialPriceParam
     */
    'threeDDataID': string;
    /**
     * 
     * @type {string}
     * @memberof GetMaterialPriceParam
     */
    'printerValue': string;
}
/**
 * 
 * @export
 * @interface GetModelNumberCheckDto
 */
export interface GetModelNumberCheckDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetModelNumberCheckDto
     */
    'checkResult': Array<string>;
}
/**
 * 
 * @export
 * @interface GetModelNumberCheckParam
 */
export interface GetModelNumberCheckParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetModelNumberCheckParam
     */
    'modelNumber': Array<string>;
}
/**
 * 
 * @export
 * @interface GetModelNumberDetailParam
 */
export interface GetModelNumberDetailParam {
    /**
     * 
     * @type {string}
     * @memberof GetModelNumberDetailParam
     */
    'modelNumber': string;
}
/**
 * 
 * @export
 * @interface GetModelNumberListParam
 */
export interface GetModelNumberListParam {
    /**
     * 
     * @type {string}
     * @memberof GetModelNumberListParam
     */
    'sortItem': string;
    /**
     * 
     * @type {string}
     * @memberof GetModelNumberListParam
     */
    'sortBy': string;
    /**
     * 
     * @type {number}
     * @memberof GetModelNumberListParam
     */
    'pageNumber': number;
    /**
     * 
     * @type {string}
     * @memberof GetModelNumberListParam
     */
    'filterItem': string;
    /**
     * 
     * @type {string}
     * @memberof GetModelNumberListParam
     */
    'searchValue'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetModelNumberListParam
     */
    'modelNumberList'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetModelNumberOptionParam
 */
export interface GetModelNumberOptionParam {
    /**
     * 
     * @type {number}
     * @memberof GetModelNumberOptionParam
     */
    'modelingMaterialId': number;
}
/**
 * 
 * @export
 * @interface GetModelNumberTrialCalcResultParam
 */
export interface GetModelNumberTrialCalcResultParam {
    /**
     * 
     * @type {string}
     * @memberof GetModelNumberTrialCalcResultParam
     */
    'threeDDataId': string;
    /**
     * 
     * @type {number}
     * @memberof GetModelNumberTrialCalcResultParam
     */
    'modelingMaterialId': number;
    /**
     * 
     * @type {string}
     * @memberof GetModelNumberTrialCalcResultParam
     */
    'methodValue': string;
    /**
     * 
     * @type {string}
     * @memberof GetModelNumberTrialCalcResultParam
     */
    'printerValue': string;
    /**
     * 
     * @type {Array<OptionListDto>}
     * @memberof GetModelNumberTrialCalcResultParam
     */
    'optionList': Array<OptionListDto>;
}
/**
 * 
 * @export
 * @interface GetPdfParam
 */
export interface GetPdfParam {
    /**
     * 
     * @type {string}
     * @memberof GetPdfParam
     */
    'specNumber': string;
}
/**
 * 
 * @export
 * @interface GetThreeDModelCalcResultParam
 */
export interface GetThreeDModelCalcResultParam {
    /**
     * 
     * @type {string}
     * @memberof GetThreeDModelCalcResultParam
     */
    'threeDDataID': string;
}
/**
 * 
 * @export
 * @interface GetThreeDModelPathDto
 */
export interface GetThreeDModelPathDto {
    /**
     * 
     * @type {string}
     * @memberof GetThreeDModelPathDto
     */
    'modelMpacKey': string;
    /**
     * 
     * @type {string}
     * @memberof GetThreeDModelPathDto
     */
    'bucketName': string;
}
/**
 * 
 * @export
 * @interface GetThreeDModelPathParam
 */
export interface GetThreeDModelPathParam {
    /**
     * 
     * @type {string}
     * @memberof GetThreeDModelPathParam
     */
    'threeDDataID': string;
}
/**
 * 
 * @export
 * @interface GetThreeDModelRecommendCalcResultParam
 */
export interface GetThreeDModelRecommendCalcResultParam {
    /**
     * 
     * @type {string}
     * @memberof GetThreeDModelRecommendCalcResultParam
     */
    'groupID': string;
}
/**
 * 
 * @export
 * @interface GetThreeDReUploadURLDto
 */
export interface GetThreeDReUploadURLDto {
    /**
     * 
     * @type {string}
     * @memberof GetThreeDReUploadURLDto
     */
    'uploadURL'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetThreeDReUploadURLDto
     */
    'errorList'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetThreeDReUploadURLParam
 */
export interface GetThreeDReUploadURLParam {
    /**
     * 
     * @type {string}
     * @memberof GetThreeDReUploadURLParam
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof GetThreeDReUploadURLParam
     */
    'threeDDataID': string;
    /**
     * 
     * @type {number}
     * @memberof GetThreeDReUploadURLParam
     */
    'fileSize': number;
    /**
     * 
     * @type {string}
     * @memberof GetThreeDReUploadURLParam
     */
    'fileHash': string;
}
/**
 * 
 * @export
 * @interface GetThreeDUploadURLDto
 */
export interface GetThreeDUploadURLDto {
    /**
     * 
     * @type {string}
     * @memberof GetThreeDUploadURLDto
     */
    'threeDDataID': string;
    /**
     * 
     * @type {string}
     * @memberof GetThreeDUploadURLDto
     */
    'uploadURL': string;
}
/**
 * 
 * @export
 * @interface GetThreeDUploadURLParam
 */
export interface GetThreeDUploadURLParam {
    /**
     * 
     * @type {string}
     * @memberof GetThreeDUploadURLParam
     */
    'fileName': string;
    /**
     * 
     * @type {number}
     * @memberof GetThreeDUploadURLParam
     */
    'fileSize': number;
}
/**
 * 
 * @export
 * @interface GetTrialCalcDetailParam
 */
export interface GetTrialCalcDetailParam {
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcDetailParam
     */
    'specNumber': string;
}
/**
 * 
 * @export
 * @interface GetTrialCalcListParam
 */
export interface GetTrialCalcListParam {
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcListParam
     */
    'sortItem': string;
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcListParam
     */
    'sortBy': string;
    /**
     * 
     * @type {number}
     * @memberof GetTrialCalcListParam
     */
    'pageNumber': number;
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcListParam
     */
    'filterItem': string;
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcListParam
     */
    'searchValue'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetTrialCalcListParam
     */
    'specNumberList'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetTrialCalcModelNumberAddListDataListDto
 */
export interface GetTrialCalcModelNumberAddListDataListDto {
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcModelNumberAddListDataListDto
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcModelNumberAddListDataListDto
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcModelNumberAddListDataListDto
     */
    'labelName': string;
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcModelNumberAddListDataListDto
     */
    'modelNumber': string;
    /**
     * 
     * @type {number}
     * @memberof GetTrialCalcModelNumberAddListDataListDto
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcModelNumberAddListDataListDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcModelNumberAddListDataListDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcModelNumberAddListDataListDto
     */
    'updateDate': string;
}
/**
 * 
 * @export
 * @interface GetTrialCalcModelNumberAddListDto
 */
export interface GetTrialCalcModelNumberAddListDto {
    /**
     * 
     * @type {Array<GetTrialCalcModelNumberAddListDataListDto>}
     * @memberof GetTrialCalcModelNumberAddListDto
     */
    'dataList': Array<GetTrialCalcModelNumberAddListDataListDto>;
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcModelNumberAddListDto
     */
    'imageBucketName': string;
    /**
     * 
     * @type {number}
     * @memberof GetTrialCalcModelNumberAddListDto
     */
    'allCount': number;
}
/**
 * 
 * @export
 * @interface GetTrialCalcModelNumberAddListParam
 */
export interface GetTrialCalcModelNumberAddListParam {
    /**
     * 
     * @type {number}
     * @memberof GetTrialCalcModelNumberAddListParam
     */
    'modelingMaterialId': number;
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcModelNumberAddListParam
     */
    'methodValue': string;
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcModelNumberAddListParam
     */
    'printerValue': string;
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcModelNumberAddListParam
     */
    'sortItem': string;
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcModelNumberAddListParam
     */
    'sortBy': string;
    /**
     * 
     * @type {number}
     * @memberof GetTrialCalcModelNumberAddListParam
     */
    'pageNumber': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetTrialCalcModelNumberAddListParam
     */
    'modelNumber': Array<string>;
}
/**
 * 
 * @export
 * @interface GetTrialCalcResultParam
 */
export interface GetTrialCalcResultParam {
    /**
     * 
     * @type {string}
     * @memberof GetTrialCalcResultParam
     */
    'specNumber'?: string;
    /**
     * 
     * @type {Array<ModelNumberParamDto>}
     * @memberof GetTrialCalcResultParam
     */
    'modelNumberList': Array<ModelNumberParamDto>;
}
/**
 * 
 * @export
 * @interface GetUserInputCheckDto
 */
export interface GetUserInputCheckDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetUserInputCheckDto
     */
    'checkResult': Array<string>;
}
/**
 * 
 * @export
 * @interface GetUserInputCheckParam
 */
export interface GetUserInputCheckParam {
    /**
     * 
     * @type {string}
     * @memberof GetUserInputCheckParam
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInputCheckParam
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInputCheckParam
     */
    'lastNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInputCheckParam
     */
    'firstNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInputCheckParam
     */
    'mailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInputCheckParam
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInputCheckParam
     */
    'corpoName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInputCheckParam
     */
    'corpoNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInputCheckParam
     */
    'departmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInputCheckParam
     */
    'postCode1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInputCheckParam
     */
    'postCode2'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInputCheckParam
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInputCheckParam
     */
    'password1': string;
    /**
     * 
     * @type {string}
     * @memberof GetUserInputCheckParam
     */
    'password2'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserInputCheckParam
     */
    'policyFlg': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserInputCheckParam
     */
    'mailFlg': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserInputCheckParam
     */
    'passwordValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserInputCheckParam
     */
    'partEmpty'?: boolean;
}
/**
 * 
 * @export
 * @interface InformationDto
 */
export interface InformationDto {
    /**
     * 
     * @type {string}
     * @memberof InformationDto
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof InformationDto
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof InformationDto
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof InformationDto
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof InformationDto
     */
    'link'?: string;
}
/**
 * 
 * @export
 * @interface MaterialDto
 */
export interface MaterialDto {
    /**
     * 
     * @type {string}
     * @memberof MaterialDto
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDto
     */
    'materialName': string;
    /**
     * 
     * @type {number}
     * @memberof MaterialDto
     */
    'modelingMaterialId': number;
    /**
     * 
     * @type {string}
     * @memberof MaterialDto
     */
    'materialMemo': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialDto
     */
    'materialImage': string;
    /**
     * 
     * @type {Array<MethodDto>}
     * @memberof MaterialDto
     */
    'methodList': Array<MethodDto>;
}
/**
 * 
 * @export
 * @interface MaterialPriceCalcResultDto
 */
export interface MaterialPriceCalcResultDto {
    /**
     * 
     * @type {string}
     * @memberof MaterialPriceCalcResultDto
     */
    'materialValue': string;
    /**
     * 
     * @type {number}
     * @memberof MaterialPriceCalcResultDto
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof MaterialPriceCalcResultDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialPriceCalcResultDto
     */
    'materialMemo': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialPriceCalcResultDto
     */
    'materialImage': string;
}
/**
 * 
 * @export
 * @interface MaterialPriceDto
 */
export interface MaterialPriceDto {
    /**
     * 
     * @type {Array<MaterialPriceCalcResultDto>}
     * @memberof MaterialPriceDto
     */
    'calcResultList': Array<MaterialPriceCalcResultDto>;
}
/**
 * 
 * @export
 * @interface MaterialResultDto
 */
export interface MaterialResultDto {
    /**
     * 
     * @type {number}
     * @memberof MaterialResultDto
     */
    'judge': number;
    /**
     * 
     * @type {string}
     * @memberof MaterialResultDto
     */
    'comment': string;
    /**
     * 
     * @type {string}
     * @memberof MaterialResultDto
     */
    'recommend'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialResultDto
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof MaterialResultDto
     */
    'title'?: string;
}
/**
 * 
 * @export
 * @interface MethodDto
 */
export interface MethodDto {
    /**
     * 
     * @type {string}
     * @memberof MethodDto
     */
    'value': string;
    /**
     * 
     * @type {Array<PrinterDto>}
     * @memberof MethodDto
     */
    'printerList': Array<PrinterDto>;
}
/**
 * 
 * @export
 * @interface ModelMpacPathDto
 */
export interface ModelMpacPathDto {
    /**
     * 
     * @type {string}
     * @memberof ModelMpacPathDto
     */
    'modelNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ModelMpacPathDto
     */
    'bucketName': string;
    /**
     * 
     * @type {string}
     * @memberof ModelMpacPathDto
     */
    'modelMpacKey'?: string;
    /**
     * 
     * @type {RotateInfoDto}
     * @memberof ModelMpacPathDto
     */
    'rotateInfo'?: RotateInfoDto;
}
/**
 * 
 * @export
 * @interface ModelNumberDataListDto
 */
export interface ModelNumberDataListDto {
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDataListDto
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDataListDto
     */
    'fileName': string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDataListDto
     */
    'labelName': string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDataListDto
     */
    'modelNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDataListDto
     */
    'materialName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDataListDto
     */
    'modelName'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelNumberDataListDto
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDataListDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDataListDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDataListDto
     */
    'updateDate': string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDataListDto
     */
    'optionStressRelief': string;
}
/**
 * 
 * @export
 * @interface ModelNumberDetailDto
 */
export interface ModelNumberDetailDto {
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'labelName': string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'modelNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'fileName': string;
    /**
     * 
     * @type {number}
     * @memberof ModelNumberDetailDto
     */
    'fileSize': number;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'materialValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelNumberDetailDto
     */
    'modelingMaterialId'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'methodValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'printerValue'?: string;
    /**
     * 
     * @type {Array<OptionDto>}
     * @memberof ModelNumberDetailDto
     */
    'optionList': Array<OptionDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ModelNumberDetailDto
     */
    'directions': boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'directionsComment'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'directionsFile'?: string;
    /**
     * 
     * @type {CalcResultDto}
     * @memberof ModelNumberDetailDto
     */
    'calcResult'?: CalcResultDto;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'memo'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelNumberDetailDto
     */
    'requireCalcFlg': boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'threeDDataID': string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'supportModelMpacKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'modelMpacKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'onPartsSupportModelMpacKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'crossSectionalNgModelKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'bucketName': string;
    /**
     * 
     * @type {RotateInfoDto}
     * @memberof ModelNumberDetailDto
     */
    'rotateInfo'?: RotateInfoDto;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'imageKey': string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDetailDto
     */
    'imageBucketName': string;
}
/**
 * 
 * @export
 * @interface ModelNumberDto
 */
export interface ModelNumberDto {
    /**
     * 
     * @type {Array<ModelNumberDataListDto>}
     * @memberof ModelNumberDto
     */
    'dataList': Array<ModelNumberDataListDto>;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberDto
     */
    'bucketName': string;
    /**
     * 
     * @type {ModelNumberFilterCountDto}
     * @memberof ModelNumberDto
     */
    'filterCount': ModelNumberFilterCountDto;
}
/**
 * 
 * @export
 * @interface ModelNumberFilterCountDto
 */
export interface ModelNumberFilterCountDto {
    /**
     * 
     * @type {number}
     * @memberof ModelNumberFilterCountDto
     */
    'all': number;
    /**
     * 
     * @type {number}
     * @memberof ModelNumberFilterCountDto
     */
    'specFixed': number;
    /**
     * 
     * @type {number}
     * @memberof ModelNumberFilterCountDto
     */
    'checkingPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ModelNumberFilterCountDto
     */
    'tempSaved': number;
}
/**
 * 
 * @export
 * @interface ModelNumberMaterialDto
 */
export interface ModelNumberMaterialDto {
    /**
     * 
     * @type {Array<MaterialDto>}
     * @memberof ModelNumberMaterialDto
     */
    'materialList': Array<MaterialDto>;
}
/**
 * 
 * @export
 * @interface ModelNumberOptionDto
 */
export interface ModelNumberOptionDto {
    /**
     * 
     * @type {Array<ModelNumberOptionOptionDto>}
     * @memberof ModelNumberOptionDto
     */
    'optionList': Array<ModelNumberOptionOptionDto>;
}
/**
 * 
 * @export
 * @interface ModelNumberOptionOptionDto
 */
export interface ModelNumberOptionOptionDto {
    /**
     * 
     * @type {string}
     * @memberof ModelNumberOptionOptionDto
     */
    'labelName': string;
    /**
     * 
     * @type {Array<OptionMenuDto>}
     * @memberof ModelNumberOptionOptionDto
     */
    'items': Array<OptionMenuDto>;
    /**
     * 
     * @type {number}
     * @memberof ModelNumberOptionOptionDto
     */
    'maxNum'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberOptionOptionDto
     */
    'selectType'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberOptionOptionDto
     */
    'exp': string;
}
/**
 * 
 * @export
 * @interface ModelNumberParamDto
 */
export interface ModelNumberParamDto {
    /**
     * 
     * @type {string}
     * @memberof ModelNumberParamDto
     */
    'modelNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ModelNumberParamDto
     */
    'num': string;
}
/**
 * 
 * @export
 * @interface ModelNumberTrialCalcResultDto
 */
export interface ModelNumberTrialCalcResultDto {
    /**
     * 
     * @type {TrialCalcCalcResultDto}
     * @memberof ModelNumberTrialCalcResultDto
     */
    'calcResult'?: TrialCalcCalcResultDto;
    /**
     * 
     * @type {TrialCalcOfTenModelsResultDto}
     * @memberof ModelNumberTrialCalcResultDto
     */
    'calcTenModelsResult'?: TrialCalcOfTenModelsResultDto;
    /**
     * 
     * @type {boolean}
     * @memberof ModelNumberTrialCalcResultDto
     */
    'contactFlg': boolean;
}
/**
 * 
 * @export
 * @interface ModelSizeDto
 */
export interface ModelSizeDto {
    /**
     * 
     * @type {string}
     * @memberof ModelSizeDto
     */
    'z'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelSizeDto
     */
    'y'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelSizeDto
     */
    'x'?: string;
}
/**
 * 
 * @export
 * @interface ModelSizeInfoListDto
 */
export interface ModelSizeInfoListDto {
    /**
     * 
     * @type {string}
     * @memberof ModelSizeInfoListDto
     */
    'modelNumber': string;
    /**
     * 
     * @type {ModelSizeDto}
     * @memberof ModelSizeInfoListDto
     */
    'size': ModelSizeDto;
}
/**
 * 
 * @export
 * @interface NormalDto
 */
export interface NormalDto {
    /**
     * 
     * @type {number}
     * @memberof NormalDto
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof NormalDto
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface OptionDto
 */
export interface OptionDto {
    /**
     * 
     * @type {string}
     * @memberof OptionDto
     */
    'labelName': string;
    /**
     * 
     * @type {string}
     * @memberof OptionDto
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof OptionDto
     */
    'num': string;
}
/**
 * 
 * @export
 * @interface OptionListDto
 */
export interface OptionListDto {
    /**
     * 
     * @type {string}
     * @memberof OptionListDto
     */
    'labelName': string;
    /**
     * 
     * @type {string}
     * @memberof OptionListDto
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof OptionListDto
     */
    'num'?: string;
}
/**
 * 
 * @export
 * @interface OptionMenuDto
 */
export interface OptionMenuDto {
    /**
     * 
     * @type {string}
     * @memberof OptionMenuDto
     */
    'value': string;
    /**
     * 
     * @type {boolean}
     * @memberof OptionMenuDto
     */
    'calcFlg': boolean;
    /**
     * 
     * @type {number}
     * @memberof OptionMenuDto
     */
    'price': number;
}
/**
 * 
 * @export
 * @interface OptionParamDto
 */
export interface OptionParamDto {
    /**
     * 
     * @type {string}
     * @memberof OptionParamDto
     */
    'labelName'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionParamDto
     */
    'value'?: string;
    /**
     * 
     * @type {string}
     * @memberof OptionParamDto
     */
    'num'?: string;
}
/**
 * 
 * @export
 * @interface OutLineDto
 */
export interface OutLineDto {
    /**
     * 
     * @type {string}
     * @memberof OutLineDto
     */
    'z'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutLineDto
     */
    'y'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutLineDto
     */
    'x'?: string;
}
/**
 * 
 * @export
 * @interface PositionListDto
 */
export interface PositionListDto {
    /**
     * 
     * @type {string}
     * @memberof PositionListDto
     */
    'modelNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PositionListDto
     */
    'positionX': string;
    /**
     * 
     * @type {string}
     * @memberof PositionListDto
     */
    'positionY': string;
    /**
     * 
     * @type {string}
     * @memberof PositionListDto
     */
    'positionZ': string;
    /**
     * 
     * @type {boolean}
     * @memberof PositionListDto
     */
    'rotated': boolean;
}
/**
 * 
 * @export
 * @interface PostThreeDModelCalcParam
 */
export interface PostThreeDModelCalcParam {
    /**
     * 
     * @type {string}
     * @memberof PostThreeDModelCalcParam
     */
    'threeDDataID': string;
    /**
     * 
     * @type {number}
     * @memberof PostThreeDModelCalcParam
     */
    'modelingMaterialId': number;
    /**
     * 
     * @type {string}
     * @memberof PostThreeDModelCalcParam
     */
    'methodValue': string;
    /**
     * 
     * @type {string}
     * @memberof PostThreeDModelCalcParam
     */
    'printerValue': string;
    /**
     * 
     * @type {CameraDto}
     * @memberof PostThreeDModelCalcParam
     */
    'camera': CameraDto;
    /**
     * 
     * @type {RotateInfoDto}
     * @memberof PostThreeDModelCalcParam
     */
    'rotateInfo': RotateInfoDto;
}
/**
 * 
 * @export
 * @interface PostThreeDModelCalcResultDto
 */
export interface PostThreeDModelCalcResultDto {
    /**
     * 
     * @type {string}
     * @memberof PostThreeDModelCalcResultDto
     */
    'newThreeDDataID'?: string;
}
/**
 * 
 * @export
 * @interface PostThreeDModelRecommendCalcParam
 */
export interface PostThreeDModelRecommendCalcParam {
    /**
     * 
     * @type {string}
     * @memberof PostThreeDModelRecommendCalcParam
     */
    'threeDDataID': string;
    /**
     * 
     * @type {number}
     * @memberof PostThreeDModelRecommendCalcParam
     */
    'modelingMaterialId': number;
    /**
     * 
     * @type {string}
     * @memberof PostThreeDModelRecommendCalcParam
     */
    'methodValue': string;
    /**
     * 
     * @type {string}
     * @memberof PostThreeDModelRecommendCalcParam
     */
    'printerValue': string;
    /**
     * 
     * @type {Array<CameraOutputDto>}
     * @memberof PostThreeDModelRecommendCalcParam
     */
    'cameraOutputDtoList': Array<CameraOutputDto>;
    /**
     * 
     * @type {Array<XyzDegDto>}
     * @memberof PostThreeDModelRecommendCalcParam
     */
    'xyzDegDtoList': Array<XyzDegDto>;
}
/**
 * 
 * @export
 * @interface PostThreeDModelRecommendCalcResultDto
 */
export interface PostThreeDModelRecommendCalcResultDto {
    /**
     * 
     * @type {string}
     * @memberof PostThreeDModelRecommendCalcResultDto
     */
    'groupID'?: string;
}
/**
 * 
 * @export
 * @interface PrinterDto
 */
export interface PrinterDto {
    /**
     * 
     * @type {string}
     * @memberof PrinterDto
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PrinterDto
     */
    'supportMaterialLimit': number;
    /**
     * 
     * @type {string}
     * @memberof PrinterDto
     */
    'basicModelingAttitude': string;
    /**
     * 
     * @type {string}
     * @memberof PrinterDto
     */
    'basicModelingAttitudeImageUrl'?: string;
    /**
     * 
     * @type {number}
     * @memberof PrinterDto
     */
    'maximumBuildSizeWidth': number;
    /**
     * 
     * @type {number}
     * @memberof PrinterDto
     */
    'maximumBuildSizeDepth': number;
    /**
     * 
     * @type {number}
     * @memberof PrinterDto
     */
    'maximumBuildSizeHeight': number;
    /**
     * 
     * @type {string}
     * @memberof PrinterDto
     */
    'modelingAreaUrl': string;
}
/**
 * 
 * @export
 * @interface PrinterSizeInfoDto
 */
export interface PrinterSizeInfoDto {
    /**
     * 
     * @type {number}
     * @memberof PrinterSizeInfoDto
     */
    'maximumBuildSizeWidth': number;
    /**
     * 
     * @type {number}
     * @memberof PrinterSizeInfoDto
     */
    'maximumBuildSizeDepth': number;
    /**
     * 
     * @type {number}
     * @memberof PrinterSizeInfoDto
     */
    'maximumBuildSizeHeight': number;
    /**
     * 
     * @type {number}
     * @memberof PrinterSizeInfoDto
     */
    'prohibitionAreaX': number;
    /**
     * 
     * @type {number}
     * @memberof PrinterSizeInfoDto
     */
    'prohibitionAreaY': number;
}
/**
 * 
 * @export
 * @interface PutContactAdressParam
 */
export interface PutContactAdressParam {
    /**
     * 
     * @type {number}
     * @memberof PutContactAdressParam
     */
    'updateFlg': number;
    /**
     * 
     * @type {string}
     * @memberof PutContactAdressParam
     */
    'updateNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof PutContactAdressParam
     */
    'contactChangeFlg': boolean;
    /**
     * 
     * @type {string}
     * @memberof PutContactAdressParam
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactAdressParam
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactAdressParam
     */
    'lastNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactAdressParam
     */
    'firstNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactAdressParam
     */
    'mailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactAdressParam
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactAdressParam
     */
    'corpoName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactAdressParam
     */
    'corpoNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactAdressParam
     */
    'departmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactAdressParam
     */
    'postCode1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactAdressParam
     */
    'postCode2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactAdressParam
     */
    'address1'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PutContactAdressParam
     */
    'partEmpty'?: boolean;
}
/**
 * 
 * @export
 * @interface PutContactCheckDto
 */
export interface PutContactCheckDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutContactCheckDto
     */
    'checkResult': Array<string>;
}
/**
 * 
 * @export
 * @interface PutContactCheckParam
 */
export interface PutContactCheckParam {
    /**
     * 
     * @type {string}
     * @memberof PutContactCheckParam
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactCheckParam
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactCheckParam
     */
    'lastNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactCheckParam
     */
    'firstNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactCheckParam
     */
    'mailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof PutContactCheckParam
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PutContactCheckParam
     */
    'corpoName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactCheckParam
     */
    'corpoNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactCheckParam
     */
    'departmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactCheckParam
     */
    'postCode1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactCheckParam
     */
    'postCode2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutContactCheckParam
     */
    'address1'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PutContactCheckParam
     */
    'partEmpty'?: boolean;
}
/**
 * 
 * @export
 * @interface PutFederatedIdParam
 */
export interface PutFederatedIdParam {
    /**
     * 
     * @type {string}
     * @memberof PutFederatedIdParam
     */
    'federatedId': string;
}
/**
 * 
 * @export
 * @interface PutModelNumberConfirmDto
 */
export interface PutModelNumberConfirmDto {
    /**
     * 
     * @type {boolean}
     * @memberof PutModelNumberConfirmDto
     */
    'confirmFlg'?: boolean;
}
/**
 * 
 * @export
 * @interface PutModelNumberConfirmParam
 */
export interface PutModelNumberConfirmParam {
    /**
     * 
     * @type {string}
     * @memberof PutModelNumberConfirmParam
     */
    'modelNumber': string;
}
/**
 * 
 * @export
 * @interface PutModelNumberDetailDto
 */
export interface PutModelNumberDetailDto {
    /**
     * 
     * @type {string}
     * @memberof PutModelNumberDetailDto
     */
    'modelNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PutModelNumberDetailDto
     */
    'directionsFileUploadUrl'?: string;
}
/**
 * 
 * @export
 * @interface PutModelNumberDetailParam
 */
export interface PutModelNumberDetailParam {
    /**
     * 
     * @type {string}
     * @memberof PutModelNumberDetailParam
     */
    'labelName': string;
    /**
     * 
     * @type {string}
     * @memberof PutModelNumberDetailParam
     */
    'modelNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PutModelNumberDetailParam
     */
    'fileName': string;
    /**
     * 
     * @type {number}
     * @memberof PutModelNumberDetailParam
     */
    'modelingMaterialId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutModelNumberDetailParam
     */
    'methodValue': string;
    /**
     * 
     * @type {string}
     * @memberof PutModelNumberDetailParam
     */
    'printerValue': string;
    /**
     * 
     * @type {Array<OptionParamDto>}
     * @memberof PutModelNumberDetailParam
     */
    'optionList': Array<OptionParamDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PutModelNumberDetailParam
     */
    'directions': boolean;
    /**
     * 
     * @type {string}
     * @memberof PutModelNumberDetailParam
     */
    'directionsFileName': string;
    /**
     * 
     * @type {string}
     * @memberof PutModelNumberDetailParam
     */
    'directionsComment': string;
    /**
     * 
     * @type {number}
     * @memberof PutModelNumberDetailParam
     */
    'directionsFileSize'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutModelNumberDetailParam
     */
    'memo': string;
    /**
     * 
     * @type {boolean}
     * @memberof PutModelNumberDetailParam
     */
    'requireCalcFlg': boolean;
    /**
     * 
     * @type {string}
     * @memberof PutModelNumberDetailParam
     */
    'threeDDataId': string;
    /**
     * 
     * @type {string}
     * @memberof PutModelNumberDetailParam
     */
    'captureSmall'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutModelNumberDetailParam
     */
    'captureLarge'?: string;
}
/**
 * 
 * @export
 * @interface PutOneTimePWDto
 */
export interface PutOneTimePWDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutOneTimePWDto
     */
    'checkResult': Array<string>;
}
/**
 * 
 * @export
 * @interface PutOneTimePWParam
 */
export interface PutOneTimePWParam {
    /**
     * 
     * @type {string}
     * @memberof PutOneTimePWParam
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof PutOneTimePWParam
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof PutOneTimePWParam
     */
    'mailAddress': string;
}
/**
 * 
 * @export
 * @interface PutQuoteAutoParam
 */
export interface PutQuoteAutoParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutQuoteAutoParam
     */
    'specNumber': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteAutoParam
     */
    'destFlg': string;
    /**
     * 
     * @type {boolean}
     * @memberof PutQuoteAutoParam
     */
    'contactChangeFlg': boolean;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteAutoParam
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteAutoParam
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteAutoParam
     */
    'lastNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteAutoParam
     */
    'firstNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteAutoParam
     */
    'mailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteAutoParam
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteAutoParam
     */
    'corpoName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteAutoParam
     */
    'corpoNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteAutoParam
     */
    'departmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteAutoParam
     */
    'postCode1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteAutoParam
     */
    'postCode2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteAutoParam
     */
    'address1'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PutQuoteAutoParam
     */
    'partEmpty'?: boolean;
}
/**
 * 
 * @export
 * @interface PutQuoteManualParam
 */
export interface PutQuoteManualParam {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutQuoteManualParam
     */
    'specNumber': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PutQuoteManualParam
     */
    'contactChangeFlg': boolean;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteManualParam
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteManualParam
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteManualParam
     */
    'lastNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteManualParam
     */
    'firstNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteManualParam
     */
    'mailAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteManualParam
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteManualParam
     */
    'corpoName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteManualParam
     */
    'corpoNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteManualParam
     */
    'departmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteManualParam
     */
    'postCode1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteManualParam
     */
    'postCode2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutQuoteManualParam
     */
    'address1'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PutQuoteManualParam
     */
    'partEmpty'?: boolean;
}
/**
 * 
 * @export
 * @interface PutTrialCalcConfirmDto
 */
export interface PutTrialCalcConfirmDto {
    /**
     * 
     * @type {boolean}
     * @memberof PutTrialCalcConfirmDto
     */
    'confirmFlg'?: boolean;
}
/**
 * 
 * @export
 * @interface PutTrialCalcConfirmParam
 */
export interface PutTrialCalcConfirmParam {
    /**
     * 
     * @type {string}
     * @memberof PutTrialCalcConfirmParam
     */
    'specNumber': string;
}
/**
 * 
 * @export
 * @interface PutTrialCalcDto
 */
export interface PutTrialCalcDto {
    /**
     * 
     * @type {string}
     * @memberof PutTrialCalcDto
     */
    'specNumber': string;
}
/**
 * 
 * @export
 * @interface PutTrialCalcParam
 */
export interface PutTrialCalcParam {
    /**
     * 
     * @type {string}
     * @memberof PutTrialCalcParam
     */
    'specNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutTrialCalcParam
     */
    'labelName': string;
    /**
     * 
     * @type {Array<ModelNumberParamDto>}
     * @memberof PutTrialCalcParam
     */
    'modelNumberList': Array<ModelNumberParamDto>;
    /**
     * 
     * @type {string}
     * @memberof PutTrialCalcParam
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof PutTrialCalcParam
     */
    'captureSmall'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PutTrialCalcParam
     */
    'captureLargeList'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PutUserConfirmParam
 */
export interface PutUserConfirmParam {
    /**
     * 
     * @type {number}
     * @memberof PutUserConfirmParam
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PutUserDto
 */
export interface PutUserDto {
    /**
     * 
     * @type {number}
     * @memberof PutUserDto
     */
    'userId': number;
}
/**
 * 
 * @export
 * @interface PutUserInfoDto
 */
export interface PutUserInfoDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof PutUserInfoDto
     */
    'checkResult': Array<string>;
}
/**
 * 
 * @export
 * @interface PutUserInfoParam
 */
export interface PutUserInfoParam {
    /**
     * 
     * @type {string}
     * @memberof PutUserInfoParam
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserInfoParam
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserInfoParam
     */
    'lastNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserInfoParam
     */
    'firstNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserInfoParam
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PutUserInfoParam
     */
    'corpoName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserInfoParam
     */
    'corpoNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserInfoParam
     */
    'departmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserInfoParam
     */
    'postCode1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserInfoParam
     */
    'postCode2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserInfoParam
     */
    'address1'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PutUserInfoParam
     */
    'mailFlg': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PutUserInfoParam
     */
    'partEmpty'?: boolean;
}
/**
 * 
 * @export
 * @interface PutUserParam
 */
export interface PutUserParam {
    /**
     * 
     * @type {string}
     * @memberof PutUserParam
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserParam
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserParam
     */
    'lastNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserParam
     */
    'firstNameKana'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserParam
     */
    'mailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof PutUserParam
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PutUserParam
     */
    'corpoName': string;
    /**
     * 
     * @type {string}
     * @memberof PutUserParam
     */
    'corpoNameKana': string;
    /**
     * 
     * @type {string}
     * @memberof PutUserParam
     */
    'departmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserParam
     */
    'postCode1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserParam
     */
    'postCode2'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserParam
     */
    'address1'?: string;
    /**
     * 
     * @type {string}
     * @memberof PutUserParam
     */
    'password1': string;
    /**
     * 
     * @type {string}
     * @memberof PutUserParam
     */
    'password2'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PutUserParam
     */
    'policyFlg': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PutUserParam
     */
    'mailFlg': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PutUserParam
     */
    'passwordValid'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PutUserParam
     */
    'partEmpty'?: boolean;
}
/**
 * 
 * @export
 * @interface QuotePdfDto
 */
export interface QuotePdfDto {
    /**
     * 
     * @type {string}
     * @memberof QuotePdfDto
     */
    'fileKey': string;
    /**
     * 
     * @type {string}
     * @memberof QuotePdfDto
     */
    'bucketName': string;
}
/**
 * 
 * @export
 * @interface RotateInfoDto
 */
export interface RotateInfoDto {
    /**
     * 
     * @type {string}
     * @memberof RotateInfoDto
     */
    'heading': string;
    /**
     * 
     * @type {string}
     * @memberof RotateInfoDto
     */
    'attitude': string;
    /**
     * 
     * @type {string}
     * @memberof RotateInfoDto
     */
    'bank': string;
}
/**
 * FRサーバが3Dモデルの計算を行った結果
 * @export
 * @interface ThreeDCalcResultParam
 */
export interface ThreeDCalcResultParam {
    /**
     * 
     * @type {string}
     * @memberof ThreeDCalcResultParam
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDCalcResultParam
     */
    'status': ThreeDCalcResultParamStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ThreeDCalcResultParam
     */
    'errorCode'?: string;
}

export const ThreeDCalcResultParamStatusEnum = {
    Unregist: 'UNREGIST',
    CheckNg: 'CHECK_NG',
    Prepare: 'PREPARE',
    Requested: 'REQUESTED',
    Calc: 'CALC',
    CalcNg: 'CALC_NG',
    Finished: 'FINISHED',
    Deleted: 'DELETED'
} as const;

export type ThreeDCalcResultParamStatusEnum = typeof ThreeDCalcResultParamStatusEnum[keyof typeof ThreeDCalcResultParamStatusEnum];

/**
 * ファイルチェック結果
 * @export
 * @interface ThreeDFileCheckResultParam
 */
export interface ThreeDFileCheckResultParam {
    /**
     * 
     * @type {string}
     * @memberof ThreeDFileCheckResultParam
     */
    'threeDDataID': string;
}
/**
 * 
 * @export
 * @interface ThreeDModelCalcResultDto
 */
export interface ThreeDModelCalcResultDto {
    /**
     * 
     * @type {number}
     * @memberof ThreeDModelCalcResultDto
     */
    'status': number;
    /**
     * 
     * @type {Array<MaterialResultDto>}
     * @memberof ThreeDModelCalcResultDto
     */
    'materialResult': Array<MaterialResultDto>;
    /**
     * 
     * @type {CalcInfoDto}
     * @memberof ThreeDModelCalcResultDto
     */
    'calcInfo'?: CalcInfoDto;
    /**
     * 
     * @type {string}
     * @memberof ThreeDModelCalcResultDto
     */
    'supportModelMpacKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDModelCalcResultDto
     */
    'onPartsSupportModelMpacKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDModelCalcResultDto
     */
    'crossSectionalNgModelKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDModelCalcResultDto
     */
    'bucketName'?: string;
}
/**
 * 
 * @export
 * @interface ThreeDModelRecommendCalcResultDto
 */
export interface ThreeDModelRecommendCalcResultDto {
    /**
     * 
     * @type {number}
     * @memberof ThreeDModelRecommendCalcResultDto
     */
    'status': number;
    /**
     * 
     * @type {string}
     * @memberof ThreeDModelRecommendCalcResultDto
     */
    'threeDDataId'?: string;
    /**
     * 
     * @type {Array<MaterialResultDto>}
     * @memberof ThreeDModelRecommendCalcResultDto
     */
    'materialResult': Array<MaterialResultDto>;
    /**
     * 
     * @type {CalcInfoDto}
     * @memberof ThreeDModelRecommendCalcResultDto
     */
    'calcInfo'?: CalcInfoDto;
    /**
     * 
     * @type {RotateInfoDto}
     * @memberof ThreeDModelRecommendCalcResultDto
     */
    'rotateInfo'?: RotateInfoDto;
    /**
     * 
     * @type {string}
     * @memberof ThreeDModelRecommendCalcResultDto
     */
    'supportModelMpacKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDModelRecommendCalcResultDto
     */
    'onPartsSupportModelMpacKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDModelRecommendCalcResultDto
     */
    'crossSectionalNgModelKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThreeDModelRecommendCalcResultDto
     */
    'bucketName'?: string;
}
/**
 * 
 * @export
 * @interface TrialCalcCalcResultDto
 */
export interface TrialCalcCalcResultDto {
    /**
     * 
     * @type {TrialCalcNormalDto}
     * @memberof TrialCalcCalcResultDto
     */
    'normal': TrialCalcNormalDto;
    /**
     * 
     * @type {TrialCalcExpressDto}
     * @memberof TrialCalcCalcResultDto
     */
    'express': TrialCalcExpressDto;
}
/**
 * 
 * @export
 * @interface TrialCalcDetailCalcResultDto
 */
export interface TrialCalcDetailCalcResultDto {
    /**
     * 
     * @type {TrialCalcDetailNormalDto}
     * @memberof TrialCalcDetailCalcResultDto
     */
    'normal': TrialCalcDetailNormalDto;
    /**
     * 
     * @type {TrialCalcDetailExpressDto}
     * @memberof TrialCalcDetailCalcResultDto
     */
    'express': TrialCalcDetailExpressDto;
}
/**
 * 
 * @export
 * @interface TrialCalcDetailDto
 */
export interface TrialCalcDetailDto {
    /**
     * 
     * @type {string}
     * @memberof TrialCalcDetailDto
     */
    'labelName': string;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcDetailDto
     */
    'specNumber': string;
    /**
     * 
     * @type {Array<TrialCalcDetailModelNumberDto>}
     * @memberof TrialCalcDetailDto
     */
    'modelNumberList': Array<TrialCalcDetailModelNumberDto>;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcDetailDto
     */
    'materialValue': string;
    /**
     * 
     * @type {number}
     * @memberof TrialCalcDetailDto
     */
    'modelingMaterialId': number;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcDetailDto
     */
    'methodValue': string;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcDetailDto
     */
    'printerValue': string;
    /**
     * 
     * @type {TrialCalcDetailCalcResultDto}
     * @memberof TrialCalcDetailDto
     */
    'calcResult'?: TrialCalcDetailCalcResultDto;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcDetailDto
     */
    'date'?: string;
    /**
     * 
     * @type {number}
     * @memberof TrialCalcDetailDto
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface TrialCalcDetailExpressDto
 */
export interface TrialCalcDetailExpressDto {
    /**
     * 
     * @type {number}
     * @memberof TrialCalcDetailExpressDto
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcDetailExpressDto
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface TrialCalcDetailModelNumberDto
 */
export interface TrialCalcDetailModelNumberDto {
    /**
     * 
     * @type {string}
     * @memberof TrialCalcDetailModelNumberDto
     */
    'modelNumber': string;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcDetailModelNumberDto
     */
    'labelName': string;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcDetailModelNumberDto
     */
    'num': string;
}
/**
 * 
 * @export
 * @interface TrialCalcDetailNormalDto
 */
export interface TrialCalcDetailNormalDto {
    /**
     * 
     * @type {number}
     * @memberof TrialCalcDetailNormalDto
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcDetailNormalDto
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface TrialCalcExpressDto
 */
export interface TrialCalcExpressDto {
    /**
     * 
     * @type {number}
     * @memberof TrialCalcExpressDto
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcExpressDto
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface TrialCalcFilterCountDto
 */
export interface TrialCalcFilterCountDto {
    /**
     * 
     * @type {number}
     * @memberof TrialCalcFilterCountDto
     */
    'all': number;
    /**
     * 
     * @type {number}
     * @memberof TrialCalcFilterCountDto
     */
    'finish': number;
    /**
     * 
     * @type {number}
     * @memberof TrialCalcFilterCountDto
     */
    'request': number;
    /**
     * 
     * @type {number}
     * @memberof TrialCalcFilterCountDto
     */
    'contract': number;
    /**
     * 
     * @type {number}
     * @memberof TrialCalcFilterCountDto
     */
    'contractWaitPrice': number;
    /**
     * 
     * @type {number}
     * @memberof TrialCalcFilterCountDto
     */
    'wait': number;
    /**
     * 
     * @type {number}
     * @memberof TrialCalcFilterCountDto
     */
    'tmpsave': number;
    /**
     * 
     * @type {number}
     * @memberof TrialCalcFilterCountDto
     */
    'humanDeal': number;
}
/**
 * 
 * @export
 * @interface TrialCalcListDataDto
 */
export interface TrialCalcListDataDto {
    /**
     * 
     * @type {string}
     * @memberof TrialCalcListDataDto
     */
    'image': string;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcListDataDto
     */
    'specNumber': string;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcListDataDto
     */
    'labelName': string;
    /**
     * 
     * @type {number}
     * @memberof TrialCalcListDataDto
     */
    'price'?: number;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcListDataDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcListDataDto
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcListDataDto
     */
    'updateDate': string;
}
/**
 * 
 * @export
 * @interface TrialCalcListDto
 */
export interface TrialCalcListDto {
    /**
     * 
     * @type {Array<TrialCalcListDataDto>}
     * @memberof TrialCalcListDto
     */
    'dataList': Array<TrialCalcListDataDto>;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcListDto
     */
    'bucketName': string;
    /**
     * 
     * @type {TrialCalcFilterCountDto}
     * @memberof TrialCalcListDto
     */
    'filterCount': TrialCalcFilterCountDto;
}
/**
 * 
 * @export
 * @interface TrialCalcNormalDto
 */
export interface TrialCalcNormalDto {
    /**
     * 
     * @type {number}
     * @memberof TrialCalcNormalDto
     */
    'price': number;
    /**
     * 
     * @type {string}
     * @memberof TrialCalcNormalDto
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface TrialCalcOfTenModelsResultDto
 */
export interface TrialCalcOfTenModelsResultDto {
    /**
     * 
     * @type {number}
     * @memberof TrialCalcOfTenModelsResultDto
     */
    'normalPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof TrialCalcOfTenModelsResultDto
     */
    'expressPrice'?: number;
}
/**
 * 
 * @export
 * @interface TrialCalcResultDto
 */
export interface TrialCalcResultDto {
    /**
     * 
     * @type {TrialCalcCalcResultDto}
     * @memberof TrialCalcResultDto
     */
    'calcResult'?: TrialCalcCalcResultDto;
    /**
     * 
     * @type {Array<ModelSizeInfoListDto>}
     * @memberof TrialCalcResultDto
     */
    'modelNumbers': Array<ModelSizeInfoListDto>;
    /**
     * 
     * @type {Array<BatchListDto>}
     * @memberof TrialCalcResultDto
     */
    'batchList': Array<BatchListDto>;
    /**
     * 
     * @type {PrinterSizeInfoDto}
     * @memberof TrialCalcResultDto
     */
    'printerSizeInfoDto': PrinterSizeInfoDto;
    /**
     * 
     * @type {Array<ModelMpacPathDto>}
     * @memberof TrialCalcResultDto
     */
    'modelMpacList': Array<ModelMpacPathDto>;
}
/**
 * 
 * @export
 * @interface UserContactAddressDto
 */
export interface UserContactAddressDto {
    /**
     * 
     * @type {string}
     * @memberof UserContactAddressDto
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof UserContactAddressDto
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof UserContactAddressDto
     */
    'lastNameKana': string;
    /**
     * 
     * @type {string}
     * @memberof UserContactAddressDto
     */
    'firstNameKana': string;
    /**
     * 
     * @type {string}
     * @memberof UserContactAddressDto
     */
    'mailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof UserContactAddressDto
     */
    'phoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof UserContactAddressDto
     */
    'corpoName': string;
    /**
     * 
     * @type {string}
     * @memberof UserContactAddressDto
     */
    'corpoNameKana': string;
    /**
     * 
     * @type {string}
     * @memberof UserContactAddressDto
     */
    'departmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserContactAddressDto
     */
    'postCode1'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserContactAddressDto
     */
    'postCode2'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserContactAddressDto
     */
    'address1'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserContactAddressDto
     */
    'mailFlg'?: boolean;
}
/**
 * 
 * @export
 * @interface VolumeDto
 */
export interface VolumeDto {
    /**
     * 
     * @type {string}
     * @memberof VolumeDto
     */
    'main': string;
    /**
     * 
     * @type {string}
     * @memberof VolumeDto
     */
    'support': string;
}
/**
 * 
 * @export
 * @interface XyzDegDto
 */
export interface XyzDegDto {
    /**
     * 
     * @type {number}
     * @memberof XyzDegDto
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof XyzDegDto
     */
    'y'?: number;
    /**
     * 
     * @type {number}
     * @memberof XyzDegDto
     */
    'z'?: number;
}

/**
 * DeleteModelNumberControllerApi - axios parameter creator
 * @export
 */
export const DeleteModelNumberControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeleteModelNumberParam} deleteModelNumberParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModelNumber: async (deleteModelNumberParam: DeleteModelNumberParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteModelNumberParam' is not null or undefined
            assertParamExists('deleteModelNumber', 'deleteModelNumberParam', deleteModelNumberParam)
            const localVarPath = `/api/deleteModelNumber`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteModelNumberParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeleteModelNumberControllerApi - functional programming interface
 * @export
 */
export const DeleteModelNumberControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeleteModelNumberControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeleteModelNumberParam} deleteModelNumberParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteModelNumber(deleteModelNumberParam: DeleteModelNumberParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteModelNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteModelNumber(deleteModelNumberParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeleteModelNumberControllerApi - factory interface
 * @export
 */
export const DeleteModelNumberControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeleteModelNumberControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {DeleteModelNumberParam} deleteModelNumberParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteModelNumber(deleteModelNumberParam: DeleteModelNumberParam, options?: any): AxiosPromise<DeleteModelNumberDto> {
            return localVarFp.deleteModelNumber(deleteModelNumberParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeleteModelNumberControllerApi - object-oriented interface
 * @export
 * @class DeleteModelNumberControllerApi
 * @extends {BaseAPI}
 */
export class DeleteModelNumberControllerApi extends BaseAPI {
    /**
     * 
     * @param {DeleteModelNumberParam} deleteModelNumberParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeleteModelNumberControllerApi
     */
    public deleteModelNumber(deleteModelNumberParam: DeleteModelNumberParam, options?: AxiosRequestConfig) {
        return DeleteModelNumberControllerApiFp(this.configuration).deleteModelNumber(deleteModelNumberParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeleteTrialCalcControllerApi - axios parameter creator
 * @export
 */
export const DeleteTrialCalcControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DeleteTrialCalcParam} deleteTrialCalcParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrialCalc: async (deleteTrialCalcParam: DeleteTrialCalcParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deleteTrialCalcParam' is not null or undefined
            assertParamExists('deleteTrialCalc', 'deleteTrialCalcParam', deleteTrialCalcParam)
            const localVarPath = `/api/deleteTrialCalc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteTrialCalcParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeleteTrialCalcControllerApi - functional programming interface
 * @export
 */
export const DeleteTrialCalcControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeleteTrialCalcControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DeleteTrialCalcParam} deleteTrialCalcParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTrialCalc(deleteTrialCalcParam: DeleteTrialCalcParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeleteTrialCalcDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTrialCalc(deleteTrialCalcParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeleteTrialCalcControllerApi - factory interface
 * @export
 */
export const DeleteTrialCalcControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeleteTrialCalcControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {DeleteTrialCalcParam} deleteTrialCalcParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrialCalc(deleteTrialCalcParam: DeleteTrialCalcParam, options?: any): AxiosPromise<DeleteTrialCalcDto> {
            return localVarFp.deleteTrialCalc(deleteTrialCalcParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeleteTrialCalcControllerApi - object-oriented interface
 * @export
 * @class DeleteTrialCalcControllerApi
 * @extends {BaseAPI}
 */
export class DeleteTrialCalcControllerApi extends BaseAPI {
    /**
     * 
     * @param {DeleteTrialCalcParam} deleteTrialCalcParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeleteTrialCalcControllerApi
     */
    public deleteTrialCalc(deleteTrialCalcParam: DeleteTrialCalcParam, options?: AxiosRequestConfig) {
        return DeleteTrialCalcControllerApiFp(this.configuration).deleteTrialCalc(deleteTrialCalcParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetCanCreateQuotationControllerApi - axios parameter creator
 * @export
 */
export const GetCanCreateQuotationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetCanCreateQuotationParam} getCanCreateQuotationParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanCreateQuotation: async (getCanCreateQuotationParam: GetCanCreateQuotationParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getCanCreateQuotationParam' is not null or undefined
            assertParamExists('getCanCreateQuotation', 'getCanCreateQuotationParam', getCanCreateQuotationParam)
            const localVarPath = `/api/getCanCreateQuotation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCanCreateQuotationParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetCanCreateQuotationControllerApi - functional programming interface
 * @export
 */
export const GetCanCreateQuotationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetCanCreateQuotationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetCanCreateQuotationParam} getCanCreateQuotationParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCanCreateQuotation(getCanCreateQuotationParam: GetCanCreateQuotationParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCanCreateQuotationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCanCreateQuotation(getCanCreateQuotationParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetCanCreateQuotationControllerApi - factory interface
 * @export
 */
export const GetCanCreateQuotationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetCanCreateQuotationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetCanCreateQuotationParam} getCanCreateQuotationParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCanCreateQuotation(getCanCreateQuotationParam: GetCanCreateQuotationParam, options?: any): AxiosPromise<GetCanCreateQuotationDto> {
            return localVarFp.getCanCreateQuotation(getCanCreateQuotationParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetCanCreateQuotationControllerApi - object-oriented interface
 * @export
 * @class GetCanCreateQuotationControllerApi
 * @extends {BaseAPI}
 */
export class GetCanCreateQuotationControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetCanCreateQuotationParam} getCanCreateQuotationParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetCanCreateQuotationControllerApi
     */
    public getCanCreateQuotation(getCanCreateQuotationParam: GetCanCreateQuotationParam, options?: AxiosRequestConfig) {
        return GetCanCreateQuotationControllerApiFp(this.configuration).getCanCreateQuotation(getCanCreateQuotationParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetChatIdentityHashControllerApi - axios parameter creator
 * @export
 */
export const GetChatIdentityHashControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatIdentityHash: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/getChatIdentityHash`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetChatIdentityHashControllerApi - functional programming interface
 * @export
 */
export const GetChatIdentityHashControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetChatIdentityHashControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChatIdentityHash(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChatIdentityHashDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChatIdentityHash(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetChatIdentityHashControllerApi - factory interface
 * @export
 */
export const GetChatIdentityHashControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetChatIdentityHashControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatIdentityHash(options?: any): AxiosPromise<GetChatIdentityHashDto> {
            return localVarFp.getChatIdentityHash(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetChatIdentityHashControllerApi - object-oriented interface
 * @export
 * @class GetChatIdentityHashControllerApi
 * @extends {BaseAPI}
 */
export class GetChatIdentityHashControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetChatIdentityHashControllerApi
     */
    public getChatIdentityHash(options?: AxiosRequestConfig) {
        return GetChatIdentityHashControllerApiFp(this.configuration).getChatIdentityHash(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetDeletedModelNumbersControllerApi - axios parameter creator
 * @export
 */
export const GetDeletedModelNumbersControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetDeletedModelNumbersParam} getDeletedModelNumbersParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeletedModelNumbers: async (getDeletedModelNumbersParam: GetDeletedModelNumbersParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getDeletedModelNumbersParam' is not null or undefined
            assertParamExists('getDeletedModelNumbers', 'getDeletedModelNumbersParam', getDeletedModelNumbersParam)
            const localVarPath = `/api/getDeletedModelNumbers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getDeletedModelNumbersParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetDeletedModelNumbersControllerApi - functional programming interface
 * @export
 */
export const GetDeletedModelNumbersControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetDeletedModelNumbersControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetDeletedModelNumbersParam} getDeletedModelNumbersParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeletedModelNumbers(getDeletedModelNumbersParam: GetDeletedModelNumbersParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDeletedModelNumbersDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeletedModelNumbers(getDeletedModelNumbersParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetDeletedModelNumbersControllerApi - factory interface
 * @export
 */
export const GetDeletedModelNumbersControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetDeletedModelNumbersControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetDeletedModelNumbersParam} getDeletedModelNumbersParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeletedModelNumbers(getDeletedModelNumbersParam: GetDeletedModelNumbersParam, options?: any): AxiosPromise<GetDeletedModelNumbersDto> {
            return localVarFp.getDeletedModelNumbers(getDeletedModelNumbersParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetDeletedModelNumbersControllerApi - object-oriented interface
 * @export
 * @class GetDeletedModelNumbersControllerApi
 * @extends {BaseAPI}
 */
export class GetDeletedModelNumbersControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetDeletedModelNumbersParam} getDeletedModelNumbersParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetDeletedModelNumbersControllerApi
     */
    public getDeletedModelNumbers(getDeletedModelNumbersParam: GetDeletedModelNumbersParam, options?: AxiosRequestConfig) {
        return GetDeletedModelNumbersControllerApiFp(this.configuration).getDeletedModelNumbers(getDeletedModelNumbersParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetHolidayListControllerApi - axios parameter creator
 * @export
 */
export const GetHolidayListControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoliday: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/getHolidayList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetHolidayListControllerApi - functional programming interface
 * @export
 */
export const GetHolidayListControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetHolidayListControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHoliday(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetHolidayDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHoliday(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetHolidayListControllerApi - factory interface
 * @export
 */
export const GetHolidayListControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetHolidayListControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHoliday(options?: any): AxiosPromise<GetHolidayDto> {
            return localVarFp.getHoliday(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetHolidayListControllerApi - object-oriented interface
 * @export
 * @class GetHolidayListControllerApi
 * @extends {BaseAPI}
 */
export class GetHolidayListControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetHolidayListControllerApi
     */
    public getHoliday(options?: AxiosRequestConfig) {
        return GetHolidayListControllerApiFp(this.configuration).getHoliday(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetInformationControllerApi - axios parameter creator
 * @export
 */
export const GetInformationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInformation: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/public/getInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetInformationControllerApi - functional programming interface
 * @export
 */
export const GetInformationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetInformationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInformation(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInformationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInformation(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetInformationControllerApi - factory interface
 * @export
 */
export const GetInformationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetInformationControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInformation(options?: any): AxiosPromise<GetInformationDto> {
            return localVarFp.getInformation(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetInformationControllerApi - object-oriented interface
 * @export
 * @class GetInformationControllerApi
 * @extends {BaseAPI}
 */
export class GetInformationControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetInformationControllerApi
     */
    public getInformation(options?: AxiosRequestConfig) {
        return GetInformationControllerApiFp(this.configuration).getInformation(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetMaterialPriceControllerApi - axios parameter creator
 * @export
 */
export const GetMaterialPriceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetMaterialPriceParam} getMaterialPriceParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaterialPrice: async (getMaterialPriceParam: GetMaterialPriceParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getMaterialPriceParam' is not null or undefined
            assertParamExists('getMaterialPrice', 'getMaterialPriceParam', getMaterialPriceParam)
            const localVarPath = `/api/getMaterialPrice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getMaterialPriceParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetMaterialPriceControllerApi - functional programming interface
 * @export
 */
export const GetMaterialPriceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetMaterialPriceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetMaterialPriceParam} getMaterialPriceParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaterialPrice(getMaterialPriceParam: GetMaterialPriceParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaterialPriceDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMaterialPrice(getMaterialPriceParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetMaterialPriceControllerApi - factory interface
 * @export
 */
export const GetMaterialPriceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetMaterialPriceControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetMaterialPriceParam} getMaterialPriceParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaterialPrice(getMaterialPriceParam: GetMaterialPriceParam, options?: any): AxiosPromise<MaterialPriceDto> {
            return localVarFp.getMaterialPrice(getMaterialPriceParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetMaterialPriceControllerApi - object-oriented interface
 * @export
 * @class GetMaterialPriceControllerApi
 * @extends {BaseAPI}
 */
export class GetMaterialPriceControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetMaterialPriceParam} getMaterialPriceParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetMaterialPriceControllerApi
     */
    public getMaterialPrice(getMaterialPriceParam: GetMaterialPriceParam, options?: AxiosRequestConfig) {
        return GetMaterialPriceControllerApiFp(this.configuration).getMaterialPrice(getMaterialPriceParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetModelNumberCheckControllerApi - axios parameter creator
 * @export
 */
export const GetModelNumberCheckControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetModelNumberCheckParam} getModelNumberCheckParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelNumberCheck: async (getModelNumberCheckParam: GetModelNumberCheckParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getModelNumberCheckParam' is not null or undefined
            assertParamExists('getModelNumberCheck', 'getModelNumberCheckParam', getModelNumberCheckParam)
            const localVarPath = `/api/getModelNumberCheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getModelNumberCheckParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetModelNumberCheckControllerApi - functional programming interface
 * @export
 */
export const GetModelNumberCheckControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetModelNumberCheckControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetModelNumberCheckParam} getModelNumberCheckParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelNumberCheck(getModelNumberCheckParam: GetModelNumberCheckParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetModelNumberCheckDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelNumberCheck(getModelNumberCheckParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetModelNumberCheckControllerApi - factory interface
 * @export
 */
export const GetModelNumberCheckControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetModelNumberCheckControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetModelNumberCheckParam} getModelNumberCheckParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelNumberCheck(getModelNumberCheckParam: GetModelNumberCheckParam, options?: any): AxiosPromise<GetModelNumberCheckDto> {
            return localVarFp.getModelNumberCheck(getModelNumberCheckParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetModelNumberCheckControllerApi - object-oriented interface
 * @export
 * @class GetModelNumberCheckControllerApi
 * @extends {BaseAPI}
 */
export class GetModelNumberCheckControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetModelNumberCheckParam} getModelNumberCheckParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetModelNumberCheckControllerApi
     */
    public getModelNumberCheck(getModelNumberCheckParam: GetModelNumberCheckParam, options?: AxiosRequestConfig) {
        return GetModelNumberCheckControllerApiFp(this.configuration).getModelNumberCheck(getModelNumberCheckParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetModelNumberDetailControllerApi - axios parameter creator
 * @export
 */
export const GetModelNumberDetailControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetModelNumberDetailParam} getModelNumberDetailParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelNumberDetail: async (getModelNumberDetailParam: GetModelNumberDetailParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getModelNumberDetailParam' is not null or undefined
            assertParamExists('getModelNumberDetail', 'getModelNumberDetailParam', getModelNumberDetailParam)
            const localVarPath = `/api/getModelNumberDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getModelNumberDetailParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetModelNumberDetailControllerApi - functional programming interface
 * @export
 */
export const GetModelNumberDetailControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetModelNumberDetailControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetModelNumberDetailParam} getModelNumberDetailParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelNumberDetail(getModelNumberDetailParam: GetModelNumberDetailParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelNumberDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelNumberDetail(getModelNumberDetailParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetModelNumberDetailControllerApi - factory interface
 * @export
 */
export const GetModelNumberDetailControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetModelNumberDetailControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetModelNumberDetailParam} getModelNumberDetailParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelNumberDetail(getModelNumberDetailParam: GetModelNumberDetailParam, options?: any): AxiosPromise<ModelNumberDetailDto> {
            return localVarFp.getModelNumberDetail(getModelNumberDetailParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetModelNumberDetailControllerApi - object-oriented interface
 * @export
 * @class GetModelNumberDetailControllerApi
 * @extends {BaseAPI}
 */
export class GetModelNumberDetailControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetModelNumberDetailParam} getModelNumberDetailParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetModelNumberDetailControllerApi
     */
    public getModelNumberDetail(getModelNumberDetailParam: GetModelNumberDetailParam, options?: AxiosRequestConfig) {
        return GetModelNumberDetailControllerApiFp(this.configuration).getModelNumberDetail(getModelNumberDetailParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetModelNumberMaterialListControllerApi - axios parameter creator
 * @export
 */
export const GetModelNumberMaterialListControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelNumberMaterialList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/getModelNumberMaterialList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetModelNumberMaterialListControllerApi - functional programming interface
 * @export
 */
export const GetModelNumberMaterialListControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetModelNumberMaterialListControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelNumberMaterialList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelNumberMaterialDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelNumberMaterialList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetModelNumberMaterialListControllerApi - factory interface
 * @export
 */
export const GetModelNumberMaterialListControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetModelNumberMaterialListControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelNumberMaterialList(options?: any): AxiosPromise<ModelNumberMaterialDto> {
            return localVarFp.getModelNumberMaterialList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetModelNumberMaterialListControllerApi - object-oriented interface
 * @export
 * @class GetModelNumberMaterialListControllerApi
 * @extends {BaseAPI}
 */
export class GetModelNumberMaterialListControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetModelNumberMaterialListControllerApi
     */
    public getModelNumberMaterialList(options?: AxiosRequestConfig) {
        return GetModelNumberMaterialListControllerApiFp(this.configuration).getModelNumberMaterialList(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetModelNumberOptionControllerApi - axios parameter creator
 * @export
 */
export const GetModelNumberOptionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetModelNumberOptionParam} getModelNumberOptionParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelNumberOption: async (getModelNumberOptionParam: GetModelNumberOptionParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getModelNumberOptionParam' is not null or undefined
            assertParamExists('getModelNumberOption', 'getModelNumberOptionParam', getModelNumberOptionParam)
            const localVarPath = `/api/getModelNumberOption`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getModelNumberOptionParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetModelNumberOptionControllerApi - functional programming interface
 * @export
 */
export const GetModelNumberOptionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetModelNumberOptionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetModelNumberOptionParam} getModelNumberOptionParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelNumberOption(getModelNumberOptionParam: GetModelNumberOptionParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelNumberOptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelNumberOption(getModelNumberOptionParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetModelNumberOptionControllerApi - factory interface
 * @export
 */
export const GetModelNumberOptionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetModelNumberOptionControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetModelNumberOptionParam} getModelNumberOptionParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelNumberOption(getModelNumberOptionParam: GetModelNumberOptionParam, options?: any): AxiosPromise<ModelNumberOptionDto> {
            return localVarFp.getModelNumberOption(getModelNumberOptionParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetModelNumberOptionControllerApi - object-oriented interface
 * @export
 * @class GetModelNumberOptionControllerApi
 * @extends {BaseAPI}
 */
export class GetModelNumberOptionControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetModelNumberOptionParam} getModelNumberOptionParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetModelNumberOptionControllerApi
     */
    public getModelNumberOption(getModelNumberOptionParam: GetModelNumberOptionParam, options?: AxiosRequestConfig) {
        return GetModelNumberOptionControllerApiFp(this.configuration).getModelNumberOption(getModelNumberOptionParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetModelNumberTrialCalcResultControllerApi - axios parameter creator
 * @export
 */
export const GetModelNumberTrialCalcResultControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetModelNumberTrialCalcResultParam} getModelNumberTrialCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelNumberTrialCalcResult: async (getModelNumberTrialCalcResultParam: GetModelNumberTrialCalcResultParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getModelNumberTrialCalcResultParam' is not null or undefined
            assertParamExists('getModelNumberTrialCalcResult', 'getModelNumberTrialCalcResultParam', getModelNumberTrialCalcResultParam)
            const localVarPath = `/api/getModelNumberTrialCalcResultLogic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getModelNumberTrialCalcResultParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetModelNumberTrialCalcResultControllerApi - functional programming interface
 * @export
 */
export const GetModelNumberTrialCalcResultControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetModelNumberTrialCalcResultControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetModelNumberTrialCalcResultParam} getModelNumberTrialCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelNumberTrialCalcResult(getModelNumberTrialCalcResultParam: GetModelNumberTrialCalcResultParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelNumberTrialCalcResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelNumberTrialCalcResult(getModelNumberTrialCalcResultParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetModelNumberTrialCalcResultControllerApi - factory interface
 * @export
 */
export const GetModelNumberTrialCalcResultControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetModelNumberTrialCalcResultControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetModelNumberTrialCalcResultParam} getModelNumberTrialCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelNumberTrialCalcResult(getModelNumberTrialCalcResultParam: GetModelNumberTrialCalcResultParam, options?: any): AxiosPromise<ModelNumberTrialCalcResultDto> {
            return localVarFp.getModelNumberTrialCalcResult(getModelNumberTrialCalcResultParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetModelNumberTrialCalcResultControllerApi - object-oriented interface
 * @export
 * @class GetModelNumberTrialCalcResultControllerApi
 * @extends {BaseAPI}
 */
export class GetModelNumberTrialCalcResultControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetModelNumberTrialCalcResultParam} getModelNumberTrialCalcResultParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetModelNumberTrialCalcResultControllerApi
     */
    public getModelNumberTrialCalcResult(getModelNumberTrialCalcResultParam: GetModelNumberTrialCalcResultParam, options?: AxiosRequestConfig) {
        return GetModelNumberTrialCalcResultControllerApiFp(this.configuration).getModelNumberTrialCalcResult(getModelNumberTrialCalcResultParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetQuotePdfControllerApi - axios parameter creator
 * @export
 */
export const GetQuotePdfControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetPdfParam} getPdfParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdf: async (getPdfParam: GetPdfParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPdfParam' is not null or undefined
            assertParamExists('getPdf', 'getPdfParam', getPdfParam)
            const localVarPath = `/api/getQuotePdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPdfParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetQuotePdfControllerApi - functional programming interface
 * @export
 */
export const GetQuotePdfControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetQuotePdfControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetPdfParam} getPdfParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPdf(getPdfParam: GetPdfParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuotePdfDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPdf(getPdfParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetQuotePdfControllerApi - factory interface
 * @export
 */
export const GetQuotePdfControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetQuotePdfControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetPdfParam} getPdfParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdf(getPdfParam: GetPdfParam, options?: any): AxiosPromise<QuotePdfDto> {
            return localVarFp.getPdf(getPdfParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetQuotePdfControllerApi - object-oriented interface
 * @export
 * @class GetQuotePdfControllerApi
 * @extends {BaseAPI}
 */
export class GetQuotePdfControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetPdfParam} getPdfParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetQuotePdfControllerApi
     */
    public getPdf(getPdfParam: GetPdfParam, options?: AxiosRequestConfig) {
        return GetQuotePdfControllerApiFp(this.configuration).getPdf(getPdfParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetThreeDModelCalcResultControllerApi - axios parameter creator
 * @export
 */
export const GetThreeDModelCalcResultControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetThreeDModelCalcResultParam} getThreeDModelCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreeDModelCalcResult: async (getThreeDModelCalcResultParam: GetThreeDModelCalcResultParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getThreeDModelCalcResultParam' is not null or undefined
            assertParamExists('getThreeDModelCalcResult', 'getThreeDModelCalcResultParam', getThreeDModelCalcResultParam)
            const localVarPath = `/api/getThreeDModelCalcResult`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getThreeDModelCalcResultParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetThreeDModelCalcResultControllerApi - functional programming interface
 * @export
 */
export const GetThreeDModelCalcResultControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetThreeDModelCalcResultControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetThreeDModelCalcResultParam} getThreeDModelCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThreeDModelCalcResult(getThreeDModelCalcResultParam: GetThreeDModelCalcResultParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreeDModelCalcResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThreeDModelCalcResult(getThreeDModelCalcResultParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetThreeDModelCalcResultControllerApi - factory interface
 * @export
 */
export const GetThreeDModelCalcResultControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetThreeDModelCalcResultControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetThreeDModelCalcResultParam} getThreeDModelCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreeDModelCalcResult(getThreeDModelCalcResultParam: GetThreeDModelCalcResultParam, options?: any): AxiosPromise<ThreeDModelCalcResultDto> {
            return localVarFp.getThreeDModelCalcResult(getThreeDModelCalcResultParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetThreeDModelCalcResultControllerApi - object-oriented interface
 * @export
 * @class GetThreeDModelCalcResultControllerApi
 * @extends {BaseAPI}
 */
export class GetThreeDModelCalcResultControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetThreeDModelCalcResultParam} getThreeDModelCalcResultParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetThreeDModelCalcResultControllerApi
     */
    public getThreeDModelCalcResult(getThreeDModelCalcResultParam: GetThreeDModelCalcResultParam, options?: AxiosRequestConfig) {
        return GetThreeDModelCalcResultControllerApiFp(this.configuration).getThreeDModelCalcResult(getThreeDModelCalcResultParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetThreeDModelPathControllerApi - axios parameter creator
 * @export
 */
export const GetThreeDModelPathControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetThreeDModelPathParam} getThreeDModelPathParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreeDModelPath: async (getThreeDModelPathParam: GetThreeDModelPathParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getThreeDModelPathParam' is not null or undefined
            assertParamExists('getThreeDModelPath', 'getThreeDModelPathParam', getThreeDModelPathParam)
            const localVarPath = `/api/getThreeDModelPath`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getThreeDModelPathParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetThreeDModelPathControllerApi - functional programming interface
 * @export
 */
export const GetThreeDModelPathControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetThreeDModelPathControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetThreeDModelPathParam} getThreeDModelPathParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThreeDModelPath(getThreeDModelPathParam: GetThreeDModelPathParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetThreeDModelPathDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThreeDModelPath(getThreeDModelPathParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetThreeDModelPathControllerApi - factory interface
 * @export
 */
export const GetThreeDModelPathControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetThreeDModelPathControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetThreeDModelPathParam} getThreeDModelPathParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreeDModelPath(getThreeDModelPathParam: GetThreeDModelPathParam, options?: any): AxiosPromise<GetThreeDModelPathDto> {
            return localVarFp.getThreeDModelPath(getThreeDModelPathParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetThreeDModelPathControllerApi - object-oriented interface
 * @export
 * @class GetThreeDModelPathControllerApi
 * @extends {BaseAPI}
 */
export class GetThreeDModelPathControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetThreeDModelPathParam} getThreeDModelPathParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetThreeDModelPathControllerApi
     */
    public getThreeDModelPath(getThreeDModelPathParam: GetThreeDModelPathParam, options?: AxiosRequestConfig) {
        return GetThreeDModelPathControllerApiFp(this.configuration).getThreeDModelPath(getThreeDModelPathParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetThreeDModelRecommendCalcResultControllerApi - axios parameter creator
 * @export
 */
export const GetThreeDModelRecommendCalcResultControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetThreeDModelRecommendCalcResultParam} getThreeDModelRecommendCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreeDModelRecommendCalcResult: async (getThreeDModelRecommendCalcResultParam: GetThreeDModelRecommendCalcResultParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getThreeDModelRecommendCalcResultParam' is not null or undefined
            assertParamExists('getThreeDModelRecommendCalcResult', 'getThreeDModelRecommendCalcResultParam', getThreeDModelRecommendCalcResultParam)
            const localVarPath = `/api/getThreeDModelRecommendCalcResult`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getThreeDModelRecommendCalcResultParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetThreeDModelRecommendCalcResultControllerApi - functional programming interface
 * @export
 */
export const GetThreeDModelRecommendCalcResultControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetThreeDModelRecommendCalcResultControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetThreeDModelRecommendCalcResultParam} getThreeDModelRecommendCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThreeDModelRecommendCalcResult(getThreeDModelRecommendCalcResultParam: GetThreeDModelRecommendCalcResultParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThreeDModelRecommendCalcResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThreeDModelRecommendCalcResult(getThreeDModelRecommendCalcResultParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetThreeDModelRecommendCalcResultControllerApi - factory interface
 * @export
 */
export const GetThreeDModelRecommendCalcResultControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetThreeDModelRecommendCalcResultControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetThreeDModelRecommendCalcResultParam} getThreeDModelRecommendCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreeDModelRecommendCalcResult(getThreeDModelRecommendCalcResultParam: GetThreeDModelRecommendCalcResultParam, options?: any): AxiosPromise<ThreeDModelRecommendCalcResultDto> {
            return localVarFp.getThreeDModelRecommendCalcResult(getThreeDModelRecommendCalcResultParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetThreeDModelRecommendCalcResultControllerApi - object-oriented interface
 * @export
 * @class GetThreeDModelRecommendCalcResultControllerApi
 * @extends {BaseAPI}
 */
export class GetThreeDModelRecommendCalcResultControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetThreeDModelRecommendCalcResultParam} getThreeDModelRecommendCalcResultParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetThreeDModelRecommendCalcResultControllerApi
     */
    public getThreeDModelRecommendCalcResult(getThreeDModelRecommendCalcResultParam: GetThreeDModelRecommendCalcResultParam, options?: AxiosRequestConfig) {
        return GetThreeDModelRecommendCalcResultControllerApiFp(this.configuration).getThreeDModelRecommendCalcResult(getThreeDModelRecommendCalcResultParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetThreeDReUploadUrlControllerApi - axios parameter creator
 * @export
 */
export const GetThreeDReUploadUrlControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetThreeDReUploadURLParam} getThreeDReUploadURLParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreeDReUploadURL: async (getThreeDReUploadURLParam: GetThreeDReUploadURLParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getThreeDReUploadURLParam' is not null or undefined
            assertParamExists('getThreeDReUploadURL', 'getThreeDReUploadURLParam', getThreeDReUploadURLParam)
            const localVarPath = `/api/getThreeDReUploadURL`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getThreeDReUploadURLParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetThreeDReUploadUrlControllerApi - functional programming interface
 * @export
 */
export const GetThreeDReUploadUrlControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetThreeDReUploadUrlControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetThreeDReUploadURLParam} getThreeDReUploadURLParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThreeDReUploadURL(getThreeDReUploadURLParam: GetThreeDReUploadURLParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetThreeDReUploadURLDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThreeDReUploadURL(getThreeDReUploadURLParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetThreeDReUploadUrlControllerApi - factory interface
 * @export
 */
export const GetThreeDReUploadUrlControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetThreeDReUploadUrlControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetThreeDReUploadURLParam} getThreeDReUploadURLParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreeDReUploadURL(getThreeDReUploadURLParam: GetThreeDReUploadURLParam, options?: any): AxiosPromise<GetThreeDReUploadURLDto> {
            return localVarFp.getThreeDReUploadURL(getThreeDReUploadURLParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetThreeDReUploadUrlControllerApi - object-oriented interface
 * @export
 * @class GetThreeDReUploadUrlControllerApi
 * @extends {BaseAPI}
 */
export class GetThreeDReUploadUrlControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetThreeDReUploadURLParam} getThreeDReUploadURLParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetThreeDReUploadUrlControllerApi
     */
    public getThreeDReUploadURL(getThreeDReUploadURLParam: GetThreeDReUploadURLParam, options?: AxiosRequestConfig) {
        return GetThreeDReUploadUrlControllerApiFp(this.configuration).getThreeDReUploadURL(getThreeDReUploadURLParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetThreeDUploadUrlControllerApi - axios parameter creator
 * @export
 */
export const GetThreeDUploadUrlControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetThreeDUploadURLParam} getThreeDUploadURLParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreeDUploadURL: async (getThreeDUploadURLParam: GetThreeDUploadURLParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getThreeDUploadURLParam' is not null or undefined
            assertParamExists('getThreeDUploadURL', 'getThreeDUploadURLParam', getThreeDUploadURLParam)
            const localVarPath = `/api/getThreeDUploadURL`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getThreeDUploadURLParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetThreeDUploadUrlControllerApi - functional programming interface
 * @export
 */
export const GetThreeDUploadUrlControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetThreeDUploadUrlControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetThreeDUploadURLParam} getThreeDUploadURLParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThreeDUploadURL(getThreeDUploadURLParam: GetThreeDUploadURLParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetThreeDUploadURLDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThreeDUploadURL(getThreeDUploadURLParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetThreeDUploadUrlControllerApi - factory interface
 * @export
 */
export const GetThreeDUploadUrlControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetThreeDUploadUrlControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetThreeDUploadURLParam} getThreeDUploadURLParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThreeDUploadURL(getThreeDUploadURLParam: GetThreeDUploadURLParam, options?: any): AxiosPromise<GetThreeDUploadURLDto> {
            return localVarFp.getThreeDUploadURL(getThreeDUploadURLParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetThreeDUploadUrlControllerApi - object-oriented interface
 * @export
 * @class GetThreeDUploadUrlControllerApi
 * @extends {BaseAPI}
 */
export class GetThreeDUploadUrlControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetThreeDUploadURLParam} getThreeDUploadURLParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetThreeDUploadUrlControllerApi
     */
    public getThreeDUploadURL(getThreeDUploadURLParam: GetThreeDUploadURLParam, options?: AxiosRequestConfig) {
        return GetThreeDUploadUrlControllerApiFp(this.configuration).getThreeDUploadURL(getThreeDUploadURLParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetTrialCalcDetailControllerApi - axios parameter creator
 * @export
 */
export const GetTrialCalcDetailControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetTrialCalcDetailParam} getTrialCalcDetailParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialCalcDetail: async (getTrialCalcDetailParam: GetTrialCalcDetailParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getTrialCalcDetailParam' is not null or undefined
            assertParamExists('getTrialCalcDetail', 'getTrialCalcDetailParam', getTrialCalcDetailParam)
            const localVarPath = `/api/getTrialCalcDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getTrialCalcDetailParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetTrialCalcDetailControllerApi - functional programming interface
 * @export
 */
export const GetTrialCalcDetailControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetTrialCalcDetailControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetTrialCalcDetailParam} getTrialCalcDetailParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrialCalcDetail(getTrialCalcDetailParam: GetTrialCalcDetailParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialCalcDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrialCalcDetail(getTrialCalcDetailParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetTrialCalcDetailControllerApi - factory interface
 * @export
 */
export const GetTrialCalcDetailControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetTrialCalcDetailControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetTrialCalcDetailParam} getTrialCalcDetailParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialCalcDetail(getTrialCalcDetailParam: GetTrialCalcDetailParam, options?: any): AxiosPromise<TrialCalcDetailDto> {
            return localVarFp.getTrialCalcDetail(getTrialCalcDetailParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetTrialCalcDetailControllerApi - object-oriented interface
 * @export
 * @class GetTrialCalcDetailControllerApi
 * @extends {BaseAPI}
 */
export class GetTrialCalcDetailControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetTrialCalcDetailParam} getTrialCalcDetailParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetTrialCalcDetailControllerApi
     */
    public getTrialCalcDetail(getTrialCalcDetailParam: GetTrialCalcDetailParam, options?: AxiosRequestConfig) {
        return GetTrialCalcDetailControllerApiFp(this.configuration).getTrialCalcDetail(getTrialCalcDetailParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetTrialCalcListControllerApi - axios parameter creator
 * @export
 */
export const GetTrialCalcListControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetTrialCalcListParam} getTrialCalcListParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialCalcList: async (getTrialCalcListParam: GetTrialCalcListParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getTrialCalcListParam' is not null or undefined
            assertParamExists('getTrialCalcList', 'getTrialCalcListParam', getTrialCalcListParam)
            const localVarPath = `/api/getTrialCalcList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getTrialCalcListParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetTrialCalcListControllerApi - functional programming interface
 * @export
 */
export const GetTrialCalcListControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetTrialCalcListControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetTrialCalcListParam} getTrialCalcListParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrialCalcList(getTrialCalcListParam: GetTrialCalcListParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialCalcListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrialCalcList(getTrialCalcListParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetTrialCalcListControllerApi - factory interface
 * @export
 */
export const GetTrialCalcListControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetTrialCalcListControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetTrialCalcListParam} getTrialCalcListParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialCalcList(getTrialCalcListParam: GetTrialCalcListParam, options?: any): AxiosPromise<TrialCalcListDto> {
            return localVarFp.getTrialCalcList(getTrialCalcListParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetTrialCalcListControllerApi - object-oriented interface
 * @export
 * @class GetTrialCalcListControllerApi
 * @extends {BaseAPI}
 */
export class GetTrialCalcListControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetTrialCalcListParam} getTrialCalcListParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetTrialCalcListControllerApi
     */
    public getTrialCalcList(getTrialCalcListParam: GetTrialCalcListParam, options?: AxiosRequestConfig) {
        return GetTrialCalcListControllerApiFp(this.configuration).getTrialCalcList(getTrialCalcListParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetTrialCalcModelNumberAddListControllerApi - axios parameter creator
 * @export
 */
export const GetTrialCalcModelNumberAddListControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetTrialCalcModelNumberAddListParam} getTrialCalcModelNumberAddListParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialCalcModelNumberAddList: async (getTrialCalcModelNumberAddListParam: GetTrialCalcModelNumberAddListParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getTrialCalcModelNumberAddListParam' is not null or undefined
            assertParamExists('getTrialCalcModelNumberAddList', 'getTrialCalcModelNumberAddListParam', getTrialCalcModelNumberAddListParam)
            const localVarPath = `/api/getTrialCalcModelNumberAddList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getTrialCalcModelNumberAddListParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetTrialCalcModelNumberAddListControllerApi - functional programming interface
 * @export
 */
export const GetTrialCalcModelNumberAddListControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetTrialCalcModelNumberAddListControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetTrialCalcModelNumberAddListParam} getTrialCalcModelNumberAddListParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrialCalcModelNumberAddList(getTrialCalcModelNumberAddListParam: GetTrialCalcModelNumberAddListParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTrialCalcModelNumberAddListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrialCalcModelNumberAddList(getTrialCalcModelNumberAddListParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetTrialCalcModelNumberAddListControllerApi - factory interface
 * @export
 */
export const GetTrialCalcModelNumberAddListControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetTrialCalcModelNumberAddListControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetTrialCalcModelNumberAddListParam} getTrialCalcModelNumberAddListParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialCalcModelNumberAddList(getTrialCalcModelNumberAddListParam: GetTrialCalcModelNumberAddListParam, options?: any): AxiosPromise<GetTrialCalcModelNumberAddListDto> {
            return localVarFp.getTrialCalcModelNumberAddList(getTrialCalcModelNumberAddListParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetTrialCalcModelNumberAddListControllerApi - object-oriented interface
 * @export
 * @class GetTrialCalcModelNumberAddListControllerApi
 * @extends {BaseAPI}
 */
export class GetTrialCalcModelNumberAddListControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetTrialCalcModelNumberAddListParam} getTrialCalcModelNumberAddListParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetTrialCalcModelNumberAddListControllerApi
     */
    public getTrialCalcModelNumberAddList(getTrialCalcModelNumberAddListParam: GetTrialCalcModelNumberAddListParam, options?: AxiosRequestConfig) {
        return GetTrialCalcModelNumberAddListControllerApiFp(this.configuration).getTrialCalcModelNumberAddList(getTrialCalcModelNumberAddListParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetTrialCalcResultControllerApi - axios parameter creator
 * @export
 */
export const GetTrialCalcResultControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetTrialCalcResultParam} getTrialCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialCalcResult: async (getTrialCalcResultParam: GetTrialCalcResultParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getTrialCalcResultParam' is not null or undefined
            assertParamExists('getTrialCalcResult', 'getTrialCalcResultParam', getTrialCalcResultParam)
            const localVarPath = `/api/getTrialCalcResult`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getTrialCalcResultParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetTrialCalcResultControllerApi - functional programming interface
 * @export
 */
export const GetTrialCalcResultControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetTrialCalcResultControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetTrialCalcResultParam} getTrialCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrialCalcResult(getTrialCalcResultParam: GetTrialCalcResultParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrialCalcResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrialCalcResult(getTrialCalcResultParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetTrialCalcResultControllerApi - factory interface
 * @export
 */
export const GetTrialCalcResultControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetTrialCalcResultControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetTrialCalcResultParam} getTrialCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialCalcResult(getTrialCalcResultParam: GetTrialCalcResultParam, options?: any): AxiosPromise<TrialCalcResultDto> {
            return localVarFp.getTrialCalcResult(getTrialCalcResultParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetTrialCalcResultControllerApi - object-oriented interface
 * @export
 * @class GetTrialCalcResultControllerApi
 * @extends {BaseAPI}
 */
export class GetTrialCalcResultControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetTrialCalcResultParam} getTrialCalcResultParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetTrialCalcResultControllerApi
     */
    public getTrialCalcResult(getTrialCalcResultParam: GetTrialCalcResultParam, options?: AxiosRequestConfig) {
        return GetTrialCalcResultControllerApiFp(this.configuration).getTrialCalcResult(getTrialCalcResultParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetTrialCalcSpecPdfControllerApi - axios parameter creator
 * @export
 */
export const GetTrialCalcSpecPdfControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} specNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialCalcSpecPdf: async (specNumber: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'specNumber' is not null or undefined
            assertParamExists('getTrialCalcSpecPdf', 'specNumber', specNumber)
            const localVarPath = `/api/getTrialCalcSpecPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (specNumber !== undefined) {
                localVarQueryParameter['specNumber'] = specNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetTrialCalcSpecPdfControllerApi - functional programming interface
 * @export
 */
export const GetTrialCalcSpecPdfControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetTrialCalcSpecPdfControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} specNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTrialCalcSpecPdf(specNumber: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTrialCalcSpecPdf(specNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetTrialCalcSpecPdfControllerApi - factory interface
 * @export
 */
export const GetTrialCalcSpecPdfControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetTrialCalcSpecPdfControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} specNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrialCalcSpecPdf(specNumber: string, options?: any): AxiosPromise<File> {
            return localVarFp.getTrialCalcSpecPdf(specNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetTrialCalcSpecPdfControllerApi - object-oriented interface
 * @export
 * @class GetTrialCalcSpecPdfControllerApi
 * @extends {BaseAPI}
 */
export class GetTrialCalcSpecPdfControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} specNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetTrialCalcSpecPdfControllerApi
     */
    public getTrialCalcSpecPdf(specNumber: string, options?: AxiosRequestConfig) {
        return GetTrialCalcSpecPdfControllerApiFp(this.configuration).getTrialCalcSpecPdf(specNumber, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetUserContactAddressControllerApi - axios parameter creator
 * @export
 */
export const GetUserContactAddressControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserContactAddress: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/getUserContactAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetUserContactAddressControllerApi - functional programming interface
 * @export
 */
export const GetUserContactAddressControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetUserContactAddressControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserContactAddress(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserContactAddressDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserContactAddress(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetUserContactAddressControllerApi - factory interface
 * @export
 */
export const GetUserContactAddressControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetUserContactAddressControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserContactAddress(options?: any): AxiosPromise<UserContactAddressDto> {
            return localVarFp.getUserContactAddress(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetUserContactAddressControllerApi - object-oriented interface
 * @export
 * @class GetUserContactAddressControllerApi
 * @extends {BaseAPI}
 */
export class GetUserContactAddressControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetUserContactAddressControllerApi
     */
    public getUserContactAddress(options?: AxiosRequestConfig) {
        return GetUserContactAddressControllerApiFp(this.configuration).getUserContactAddress(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GetUserInputCheckControllerApi - axios parameter creator
 * @export
 */
export const GetUserInputCheckControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetUserInputCheckParam} getUserInputCheckParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInputCheck: async (getUserInputCheckParam: GetUserInputCheckParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getUserInputCheckParam' is not null or undefined
            assertParamExists('getUserInputCheck', 'getUserInputCheckParam', getUserInputCheckParam)
            const localVarPath = `/api/public/getUserInputCheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getUserInputCheckParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GetUserInputCheckControllerApi - functional programming interface
 * @export
 */
export const GetUserInputCheckControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GetUserInputCheckControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetUserInputCheckParam} getUserInputCheckParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserInputCheck(getUserInputCheckParam: GetUserInputCheckParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserInputCheckDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserInputCheck(getUserInputCheckParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GetUserInputCheckControllerApi - factory interface
 * @export
 */
export const GetUserInputCheckControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GetUserInputCheckControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetUserInputCheckParam} getUserInputCheckParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserInputCheck(getUserInputCheckParam: GetUserInputCheckParam, options?: any): AxiosPromise<GetUserInputCheckDto> {
            return localVarFp.getUserInputCheck(getUserInputCheckParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GetUserInputCheckControllerApi - object-oriented interface
 * @export
 * @class GetUserInputCheckControllerApi
 * @extends {BaseAPI}
 */
export class GetUserInputCheckControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetUserInputCheckParam} getUserInputCheckParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GetUserInputCheckControllerApi
     */
    public getUserInputCheck(getUserInputCheckParam: GetUserInputCheckParam, options?: AxiosRequestConfig) {
        return GetUserInputCheckControllerApiFp(this.configuration).getUserInputCheck(getUserInputCheckParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthCheckControllerApi - axios parameter creator
 * @export
 */
export const HealthCheckControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        check: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCheckControllerApi - functional programming interface
 * @export
 */
export const HealthCheckControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthCheckControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async check(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.check(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthCheckControllerApi - factory interface
 * @export
 */
export const HealthCheckControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthCheckControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        check(options?: any): AxiosPromise<string> {
            return localVarFp.check(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthCheckControllerApi - object-oriented interface
 * @export
 * @class HealthCheckControllerApi
 * @extends {BaseAPI}
 */
export class HealthCheckControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckControllerApi
     */
    public check(options?: AxiosRequestConfig) {
        return HealthCheckControllerApiFp(this.configuration).check(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModelNumberListControllerApi - axios parameter creator
 * @export
 */
export const ModelNumberListControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {GetModelNumberListParam} getModelNumberListParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelNumberList: async (getModelNumberListParam: GetModelNumberListParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getModelNumberListParam' is not null or undefined
            assertParamExists('getModelNumberList', 'getModelNumberListParam', getModelNumberListParam)
            const localVarPath = `/api/getModelNumberList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getModelNumberListParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModelNumberListControllerApi - functional programming interface
 * @export
 */
export const ModelNumberListControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModelNumberListControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {GetModelNumberListParam} getModelNumberListParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModelNumberList(getModelNumberListParam: GetModelNumberListParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ModelNumberDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModelNumberList(getModelNumberListParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModelNumberListControllerApi - factory interface
 * @export
 */
export const ModelNumberListControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModelNumberListControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {GetModelNumberListParam} getModelNumberListParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModelNumberList(getModelNumberListParam: GetModelNumberListParam, options?: any): AxiosPromise<ModelNumberDto> {
            return localVarFp.getModelNumberList(getModelNumberListParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModelNumberListControllerApi - object-oriented interface
 * @export
 * @class ModelNumberListControllerApi
 * @extends {BaseAPI}
 */
export class ModelNumberListControllerApi extends BaseAPI {
    /**
     * 
     * @param {GetModelNumberListParam} getModelNumberListParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModelNumberListControllerApi
     */
    public getModelNumberList(getModelNumberListParam: GetModelNumberListParam, options?: AxiosRequestConfig) {
        return ModelNumberListControllerApiFp(this.configuration).getModelNumberList(getModelNumberListParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostThreeDDataUploadFinishedControllerApi - axios parameter creator
 * @export
 */
export const PostThreeDDataUploadFinishedControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ファイルチェックの結果を登録します
         * @param {ThreeDFileCheckResultParam} threeDFileCheckResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postThreeDDataUploadFinished: async (threeDFileCheckResultParam: ThreeDFileCheckResultParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threeDFileCheckResultParam' is not null or undefined
            assertParamExists('postThreeDDataUploadFinished', 'threeDFileCheckResultParam', threeDFileCheckResultParam)
            const localVarPath = `/api/postThreeDDataUploadFinished`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(threeDFileCheckResultParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostThreeDDataUploadFinishedControllerApi - functional programming interface
 * @export
 */
export const PostThreeDDataUploadFinishedControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostThreeDDataUploadFinishedControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ファイルチェックの結果を登録します
         * @param {ThreeDFileCheckResultParam} threeDFileCheckResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postThreeDDataUploadFinished(threeDFileCheckResultParam: ThreeDFileCheckResultParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postThreeDDataUploadFinished(threeDFileCheckResultParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostThreeDDataUploadFinishedControllerApi - factory interface
 * @export
 */
export const PostThreeDDataUploadFinishedControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostThreeDDataUploadFinishedControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary ファイルチェックの結果を登録します
         * @param {ThreeDFileCheckResultParam} threeDFileCheckResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postThreeDDataUploadFinished(threeDFileCheckResultParam: ThreeDFileCheckResultParam, options?: any): AxiosPromise<string> {
            return localVarFp.postThreeDDataUploadFinished(threeDFileCheckResultParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostThreeDDataUploadFinishedControllerApi - object-oriented interface
 * @export
 * @class PostThreeDDataUploadFinishedControllerApi
 * @extends {BaseAPI}
 */
export class PostThreeDDataUploadFinishedControllerApi extends BaseAPI {
    /**
     * 
     * @summary ファイルチェックの結果を登録します
     * @param {ThreeDFileCheckResultParam} threeDFileCheckResultParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostThreeDDataUploadFinishedControllerApi
     */
    public postThreeDDataUploadFinished(threeDFileCheckResultParam: ThreeDFileCheckResultParam, options?: AxiosRequestConfig) {
        return PostThreeDDataUploadFinishedControllerApiFp(this.configuration).postThreeDDataUploadFinished(threeDFileCheckResultParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostThreeDModelCalcControllerApi - axios parameter creator
 * @export
 */
export const PostThreeDModelCalcControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PostThreeDModelCalcParam} postThreeDModelCalcParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postThreeDModelCalc: async (postThreeDModelCalcParam: PostThreeDModelCalcParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postThreeDModelCalcParam' is not null or undefined
            assertParamExists('postThreeDModelCalc', 'postThreeDModelCalcParam', postThreeDModelCalcParam)
            const localVarPath = `/api/postThreeDModelCalc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postThreeDModelCalcParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostThreeDModelCalcControllerApi - functional programming interface
 * @export
 */
export const PostThreeDModelCalcControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostThreeDModelCalcControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PostThreeDModelCalcParam} postThreeDModelCalcParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postThreeDModelCalc(postThreeDModelCalcParam: PostThreeDModelCalcParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostThreeDModelCalcResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postThreeDModelCalc(postThreeDModelCalcParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostThreeDModelCalcControllerApi - factory interface
 * @export
 */
export const PostThreeDModelCalcControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostThreeDModelCalcControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PostThreeDModelCalcParam} postThreeDModelCalcParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postThreeDModelCalc(postThreeDModelCalcParam: PostThreeDModelCalcParam, options?: any): AxiosPromise<PostThreeDModelCalcResultDto> {
            return localVarFp.postThreeDModelCalc(postThreeDModelCalcParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostThreeDModelCalcControllerApi - object-oriented interface
 * @export
 * @class PostThreeDModelCalcControllerApi
 * @extends {BaseAPI}
 */
export class PostThreeDModelCalcControllerApi extends BaseAPI {
    /**
     * 
     * @param {PostThreeDModelCalcParam} postThreeDModelCalcParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostThreeDModelCalcControllerApi
     */
    public postThreeDModelCalc(postThreeDModelCalcParam: PostThreeDModelCalcParam, options?: AxiosRequestConfig) {
        return PostThreeDModelCalcControllerApiFp(this.configuration).postThreeDModelCalc(postThreeDModelCalcParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostThreeDModelRecommendCalcControllerApi - axios parameter creator
 * @export
 */
export const PostThreeDModelRecommendCalcControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PostThreeDModelRecommendCalcParam} postThreeDModelRecommendCalcParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postThreeDModelRecommendCalc: async (postThreeDModelRecommendCalcParam: PostThreeDModelRecommendCalcParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postThreeDModelRecommendCalcParam' is not null or undefined
            assertParamExists('postThreeDModelRecommendCalc', 'postThreeDModelRecommendCalcParam', postThreeDModelRecommendCalcParam)
            const localVarPath = `/api/postThreeDModelRecommendCalc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postThreeDModelRecommendCalcParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostThreeDModelRecommendCalcControllerApi - functional programming interface
 * @export
 */
export const PostThreeDModelRecommendCalcControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostThreeDModelRecommendCalcControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PostThreeDModelRecommendCalcParam} postThreeDModelRecommendCalcParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postThreeDModelRecommendCalc(postThreeDModelRecommendCalcParam: PostThreeDModelRecommendCalcParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostThreeDModelRecommendCalcResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postThreeDModelRecommendCalc(postThreeDModelRecommendCalcParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostThreeDModelRecommendCalcControllerApi - factory interface
 * @export
 */
export const PostThreeDModelRecommendCalcControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostThreeDModelRecommendCalcControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PostThreeDModelRecommendCalcParam} postThreeDModelRecommendCalcParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postThreeDModelRecommendCalc(postThreeDModelRecommendCalcParam: PostThreeDModelRecommendCalcParam, options?: any): AxiosPromise<PostThreeDModelRecommendCalcResultDto> {
            return localVarFp.postThreeDModelRecommendCalc(postThreeDModelRecommendCalcParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostThreeDModelRecommendCalcControllerApi - object-oriented interface
 * @export
 * @class PostThreeDModelRecommendCalcControllerApi
 * @extends {BaseAPI}
 */
export class PostThreeDModelRecommendCalcControllerApi extends BaseAPI {
    /**
     * 
     * @param {PostThreeDModelRecommendCalcParam} postThreeDModelRecommendCalcParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostThreeDModelRecommendCalcControllerApi
     */
    public postThreeDModelRecommendCalc(postThreeDModelRecommendCalcParam: PostThreeDModelRecommendCalcParam, options?: AxiosRequestConfig) {
        return PostThreeDModelRecommendCalcControllerApiFp(this.configuration).postThreeDModelRecommendCalc(postThreeDModelRecommendCalcParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutContactAddressControllerApi - axios parameter creator
 * @export
 */
export const PutContactAddressControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PutContactAdressParam} putContactAdressParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContactAddress: async (putContactAdressParam: PutContactAdressParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putContactAdressParam' is not null or undefined
            assertParamExists('putContactAddress', 'putContactAdressParam', putContactAdressParam)
            const localVarPath = `/api/putContactAddress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putContactAdressParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutContactAddressControllerApi - functional programming interface
 * @export
 */
export const PutContactAddressControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutContactAddressControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PutContactAdressParam} putContactAdressParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContactAddress(putContactAdressParam: PutContactAdressParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putContactAddress(putContactAdressParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutContactAddressControllerApi - factory interface
 * @export
 */
export const PutContactAddressControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutContactAddressControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PutContactAdressParam} putContactAdressParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContactAddress(putContactAdressParam: PutContactAdressParam, options?: any): AxiosPromise<void> {
            return localVarFp.putContactAddress(putContactAdressParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutContactAddressControllerApi - object-oriented interface
 * @export
 * @class PutContactAddressControllerApi
 * @extends {BaseAPI}
 */
export class PutContactAddressControllerApi extends BaseAPI {
    /**
     * 
     * @param {PutContactAdressParam} putContactAdressParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutContactAddressControllerApi
     */
    public putContactAddress(putContactAdressParam: PutContactAdressParam, options?: AxiosRequestConfig) {
        return PutContactAddressControllerApiFp(this.configuration).putContactAddress(putContactAdressParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutContactCheckControllerApi - axios parameter creator
 * @export
 */
export const PutContactCheckControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PutContactCheckParam} putContactCheckParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContactCheck: async (putContactCheckParam: PutContactCheckParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putContactCheckParam' is not null or undefined
            assertParamExists('putContactCheck', 'putContactCheckParam', putContactCheckParam)
            const localVarPath = `/api/putContactCheck`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putContactCheckParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutContactCheckControllerApi - functional programming interface
 * @export
 */
export const PutContactCheckControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutContactCheckControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PutContactCheckParam} putContactCheckParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContactCheck(putContactCheckParam: PutContactCheckParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutContactCheckDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putContactCheck(putContactCheckParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutContactCheckControllerApi - factory interface
 * @export
 */
export const PutContactCheckControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutContactCheckControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PutContactCheckParam} putContactCheckParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContactCheck(putContactCheckParam: PutContactCheckParam, options?: any): AxiosPromise<PutContactCheckDto> {
            return localVarFp.putContactCheck(putContactCheckParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutContactCheckControllerApi - object-oriented interface
 * @export
 * @class PutContactCheckControllerApi
 * @extends {BaseAPI}
 */
export class PutContactCheckControllerApi extends BaseAPI {
    /**
     * 
     * @param {PutContactCheckParam} putContactCheckParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutContactCheckControllerApi
     */
    public putContactCheck(putContactCheckParam: PutContactCheckParam, options?: AxiosRequestConfig) {
        return PutContactCheckControllerApiFp(this.configuration).putContactCheck(putContactCheckParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutFederatedIdControllerApi - axios parameter creator
 * @export
 */
export const PutFederatedIdControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PutFederatedIdParam} putFederatedIdParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFederatedId: async (putFederatedIdParam: PutFederatedIdParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putFederatedIdParam' is not null or undefined
            assertParamExists('putFederatedId', 'putFederatedIdParam', putFederatedIdParam)
            const localVarPath = `/api/putFederatedId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putFederatedIdParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutFederatedIdControllerApi - functional programming interface
 * @export
 */
export const PutFederatedIdControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutFederatedIdControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PutFederatedIdParam} putFederatedIdParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFederatedId(putFederatedIdParam: PutFederatedIdParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFederatedId(putFederatedIdParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutFederatedIdControllerApi - factory interface
 * @export
 */
export const PutFederatedIdControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutFederatedIdControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PutFederatedIdParam} putFederatedIdParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFederatedId(putFederatedIdParam: PutFederatedIdParam, options?: any): AxiosPromise<string> {
            return localVarFp.putFederatedId(putFederatedIdParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutFederatedIdControllerApi - object-oriented interface
 * @export
 * @class PutFederatedIdControllerApi
 * @extends {BaseAPI}
 */
export class PutFederatedIdControllerApi extends BaseAPI {
    /**
     * 
     * @param {PutFederatedIdParam} putFederatedIdParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutFederatedIdControllerApi
     */
    public putFederatedId(putFederatedIdParam: PutFederatedIdParam, options?: AxiosRequestConfig) {
        return PutFederatedIdControllerApiFp(this.configuration).putFederatedId(putFederatedIdParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutMailAddressChangedControllerApi - axios parameter creator
 * @export
 */
export const PutMailAddressChangedControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailAddressChanged: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/putMailAddressChanged`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutMailAddressChangedControllerApi - functional programming interface
 * @export
 */
export const PutMailAddressChangedControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutMailAddressChangedControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putMailAddressChanged(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putMailAddressChanged(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutMailAddressChangedControllerApi - factory interface
 * @export
 */
export const PutMailAddressChangedControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutMailAddressChangedControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putMailAddressChanged(options?: any): AxiosPromise<string> {
            return localVarFp.putMailAddressChanged(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutMailAddressChangedControllerApi - object-oriented interface
 * @export
 * @class PutMailAddressChangedControllerApi
 * @extends {BaseAPI}
 */
export class PutMailAddressChangedControllerApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutMailAddressChangedControllerApi
     */
    public putMailAddressChanged(options?: AxiosRequestConfig) {
        return PutMailAddressChangedControllerApiFp(this.configuration).putMailAddressChanged(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutModelNumberConfirmControllerApi - axios parameter creator
 * @export
 */
export const PutModelNumberConfirmControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PutModelNumberConfirmParam} putModelNumberConfirmParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putModelNumberConfirm: async (putModelNumberConfirmParam: PutModelNumberConfirmParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putModelNumberConfirmParam' is not null or undefined
            assertParamExists('putModelNumberConfirm', 'putModelNumberConfirmParam', putModelNumberConfirmParam)
            const localVarPath = `/api/putModelNumberConfirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putModelNumberConfirmParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutModelNumberConfirmControllerApi - functional programming interface
 * @export
 */
export const PutModelNumberConfirmControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutModelNumberConfirmControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PutModelNumberConfirmParam} putModelNumberConfirmParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putModelNumberConfirm(putModelNumberConfirmParam: PutModelNumberConfirmParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutModelNumberConfirmDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putModelNumberConfirm(putModelNumberConfirmParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutModelNumberConfirmControllerApi - factory interface
 * @export
 */
export const PutModelNumberConfirmControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutModelNumberConfirmControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PutModelNumberConfirmParam} putModelNumberConfirmParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putModelNumberConfirm(putModelNumberConfirmParam: PutModelNumberConfirmParam, options?: any): AxiosPromise<PutModelNumberConfirmDto> {
            return localVarFp.putModelNumberConfirm(putModelNumberConfirmParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutModelNumberConfirmControllerApi - object-oriented interface
 * @export
 * @class PutModelNumberConfirmControllerApi
 * @extends {BaseAPI}
 */
export class PutModelNumberConfirmControllerApi extends BaseAPI {
    /**
     * 
     * @param {PutModelNumberConfirmParam} putModelNumberConfirmParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutModelNumberConfirmControllerApi
     */
    public putModelNumberConfirm(putModelNumberConfirmParam: PutModelNumberConfirmParam, options?: AxiosRequestConfig) {
        return PutModelNumberConfirmControllerApiFp(this.configuration).putModelNumberConfirm(putModelNumberConfirmParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutModelNumberDetailControllerApi - axios parameter creator
 * @export
 */
export const PutModelNumberDetailControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PutModelNumberDetailParam} putModelNumberDetailParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putModelNumberDetail: async (putModelNumberDetailParam: PutModelNumberDetailParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putModelNumberDetailParam' is not null or undefined
            assertParamExists('putModelNumberDetail', 'putModelNumberDetailParam', putModelNumberDetailParam)
            const localVarPath = `/api/putModelNumberDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putModelNumberDetailParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutModelNumberDetailControllerApi - functional programming interface
 * @export
 */
export const PutModelNumberDetailControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutModelNumberDetailControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PutModelNumberDetailParam} putModelNumberDetailParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putModelNumberDetail(putModelNumberDetailParam: PutModelNumberDetailParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutModelNumberDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putModelNumberDetail(putModelNumberDetailParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutModelNumberDetailControllerApi - factory interface
 * @export
 */
export const PutModelNumberDetailControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutModelNumberDetailControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PutModelNumberDetailParam} putModelNumberDetailParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putModelNumberDetail(putModelNumberDetailParam: PutModelNumberDetailParam, options?: any): AxiosPromise<PutModelNumberDetailDto> {
            return localVarFp.putModelNumberDetail(putModelNumberDetailParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutModelNumberDetailControllerApi - object-oriented interface
 * @export
 * @class PutModelNumberDetailControllerApi
 * @extends {BaseAPI}
 */
export class PutModelNumberDetailControllerApi extends BaseAPI {
    /**
     * 
     * @param {PutModelNumberDetailParam} putModelNumberDetailParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutModelNumberDetailControllerApi
     */
    public putModelNumberDetail(putModelNumberDetailParam: PutModelNumberDetailParam, options?: AxiosRequestConfig) {
        return PutModelNumberDetailControllerApiFp(this.configuration).putModelNumberDetail(putModelNumberDetailParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutOneTimePwControllerApi - axios parameter creator
 * @export
 */
export const PutOneTimePwControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PutOneTimePWParam} putOneTimePWParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOneTimePW: async (putOneTimePWParam: PutOneTimePWParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putOneTimePWParam' is not null or undefined
            assertParamExists('putOneTimePW', 'putOneTimePWParam', putOneTimePWParam)
            const localVarPath = `/api/public/putOneTimePW`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putOneTimePWParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutOneTimePwControllerApi - functional programming interface
 * @export
 */
export const PutOneTimePwControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutOneTimePwControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PutOneTimePWParam} putOneTimePWParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putOneTimePW(putOneTimePWParam: PutOneTimePWParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutOneTimePWDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putOneTimePW(putOneTimePWParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutOneTimePwControllerApi - factory interface
 * @export
 */
export const PutOneTimePwControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutOneTimePwControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PutOneTimePWParam} putOneTimePWParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOneTimePW(putOneTimePWParam: PutOneTimePWParam, options?: any): AxiosPromise<PutOneTimePWDto> {
            return localVarFp.putOneTimePW(putOneTimePWParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutOneTimePwControllerApi - object-oriented interface
 * @export
 * @class PutOneTimePwControllerApi
 * @extends {BaseAPI}
 */
export class PutOneTimePwControllerApi extends BaseAPI {
    /**
     * 
     * @param {PutOneTimePWParam} putOneTimePWParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutOneTimePwControllerApi
     */
    public putOneTimePW(putOneTimePWParam: PutOneTimePWParam, options?: AxiosRequestConfig) {
        return PutOneTimePwControllerApiFp(this.configuration).putOneTimePW(putOneTimePWParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutQuoteControllerApi - axios parameter creator
 * @export
 */
export const PutQuoteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PutQuoteAutoParam} putQuoteAutoParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putQuoteAuto: async (putQuoteAutoParam: PutQuoteAutoParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putQuoteAutoParam' is not null or undefined
            assertParamExists('putQuoteAuto', 'putQuoteAutoParam', putQuoteAutoParam)
            const localVarPath = `/api/putQuoteAuto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putQuoteAutoParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutQuoteControllerApi - functional programming interface
 * @export
 */
export const PutQuoteControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutQuoteControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PutQuoteAutoParam} putQuoteAutoParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putQuoteAuto(putQuoteAutoParam: PutQuoteAutoParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putQuoteAuto(putQuoteAutoParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutQuoteControllerApi - factory interface
 * @export
 */
export const PutQuoteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutQuoteControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PutQuoteAutoParam} putQuoteAutoParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putQuoteAuto(putQuoteAutoParam: PutQuoteAutoParam, options?: any): AxiosPromise<void> {
            return localVarFp.putQuoteAuto(putQuoteAutoParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutQuoteControllerApi - object-oriented interface
 * @export
 * @class PutQuoteControllerApi
 * @extends {BaseAPI}
 */
export class PutQuoteControllerApi extends BaseAPI {
    /**
     * 
     * @param {PutQuoteAutoParam} putQuoteAutoParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutQuoteControllerApi
     */
    public putQuoteAuto(putQuoteAutoParam: PutQuoteAutoParam, options?: AxiosRequestConfig) {
        return PutQuoteControllerApiFp(this.configuration).putQuoteAuto(putQuoteAutoParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutQuoteManualControllerApi - axios parameter creator
 * @export
 */
export const PutQuoteManualControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PutQuoteManualParam} putQuoteManualParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putQuoteManual: async (putQuoteManualParam: PutQuoteManualParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putQuoteManualParam' is not null or undefined
            assertParamExists('putQuoteManual', 'putQuoteManualParam', putQuoteManualParam)
            const localVarPath = `/api/putQuoteManual`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putQuoteManualParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutQuoteManualControllerApi - functional programming interface
 * @export
 */
export const PutQuoteManualControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutQuoteManualControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PutQuoteManualParam} putQuoteManualParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putQuoteManual(putQuoteManualParam: PutQuoteManualParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putQuoteManual(putQuoteManualParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutQuoteManualControllerApi - factory interface
 * @export
 */
export const PutQuoteManualControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutQuoteManualControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PutQuoteManualParam} putQuoteManualParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putQuoteManual(putQuoteManualParam: PutQuoteManualParam, options?: any): AxiosPromise<void> {
            return localVarFp.putQuoteManual(putQuoteManualParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutQuoteManualControllerApi - object-oriented interface
 * @export
 * @class PutQuoteManualControllerApi
 * @extends {BaseAPI}
 */
export class PutQuoteManualControllerApi extends BaseAPI {
    /**
     * 
     * @param {PutQuoteManualParam} putQuoteManualParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutQuoteManualControllerApi
     */
    public putQuoteManual(putQuoteManualParam: PutQuoteManualParam, options?: AxiosRequestConfig) {
        return PutQuoteManualControllerApiFp(this.configuration).putQuoteManual(putQuoteManualParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutTrialCalcConfirmControllerApi - axios parameter creator
 * @export
 */
export const PutTrialCalcConfirmControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PutTrialCalcConfirmParam} putTrialCalcConfirmParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTrialCalcConfirm: async (putTrialCalcConfirmParam: PutTrialCalcConfirmParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putTrialCalcConfirmParam' is not null or undefined
            assertParamExists('putTrialCalcConfirm', 'putTrialCalcConfirmParam', putTrialCalcConfirmParam)
            const localVarPath = `/api/putTrialCalcConfirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putTrialCalcConfirmParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutTrialCalcConfirmControllerApi - functional programming interface
 * @export
 */
export const PutTrialCalcConfirmControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutTrialCalcConfirmControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PutTrialCalcConfirmParam} putTrialCalcConfirmParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTrialCalcConfirm(putTrialCalcConfirmParam: PutTrialCalcConfirmParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutTrialCalcConfirmDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTrialCalcConfirm(putTrialCalcConfirmParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutTrialCalcConfirmControllerApi - factory interface
 * @export
 */
export const PutTrialCalcConfirmControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutTrialCalcConfirmControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PutTrialCalcConfirmParam} putTrialCalcConfirmParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTrialCalcConfirm(putTrialCalcConfirmParam: PutTrialCalcConfirmParam, options?: any): AxiosPromise<PutTrialCalcConfirmDto> {
            return localVarFp.putTrialCalcConfirm(putTrialCalcConfirmParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutTrialCalcConfirmControllerApi - object-oriented interface
 * @export
 * @class PutTrialCalcConfirmControllerApi
 * @extends {BaseAPI}
 */
export class PutTrialCalcConfirmControllerApi extends BaseAPI {
    /**
     * 
     * @param {PutTrialCalcConfirmParam} putTrialCalcConfirmParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutTrialCalcConfirmControllerApi
     */
    public putTrialCalcConfirm(putTrialCalcConfirmParam: PutTrialCalcConfirmParam, options?: AxiosRequestConfig) {
        return PutTrialCalcConfirmControllerApiFp(this.configuration).putTrialCalcConfirm(putTrialCalcConfirmParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutTrialCalcControllerApi - axios parameter creator
 * @export
 */
export const PutTrialCalcControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PutTrialCalcParam} putTrialCalcParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTrialCalc: async (putTrialCalcParam: PutTrialCalcParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putTrialCalcParam' is not null or undefined
            assertParamExists('putTrialCalc', 'putTrialCalcParam', putTrialCalcParam)
            const localVarPath = `/api/putTrialCalc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putTrialCalcParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutTrialCalcControllerApi - functional programming interface
 * @export
 */
export const PutTrialCalcControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutTrialCalcControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PutTrialCalcParam} putTrialCalcParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTrialCalc(putTrialCalcParam: PutTrialCalcParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutTrialCalcDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTrialCalc(putTrialCalcParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutTrialCalcControllerApi - factory interface
 * @export
 */
export const PutTrialCalcControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutTrialCalcControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PutTrialCalcParam} putTrialCalcParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTrialCalc(putTrialCalcParam: PutTrialCalcParam, options?: any): AxiosPromise<PutTrialCalcDto> {
            return localVarFp.putTrialCalc(putTrialCalcParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutTrialCalcControllerApi - object-oriented interface
 * @export
 * @class PutTrialCalcControllerApi
 * @extends {BaseAPI}
 */
export class PutTrialCalcControllerApi extends BaseAPI {
    /**
     * 
     * @param {PutTrialCalcParam} putTrialCalcParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutTrialCalcControllerApi
     */
    public putTrialCalc(putTrialCalcParam: PutTrialCalcParam, options?: AxiosRequestConfig) {
        return PutTrialCalcControllerApiFp(this.configuration).putTrialCalc(putTrialCalcParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutUserConfirmControllerApi - axios parameter creator
 * @export
 */
export const PutUserConfirmControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PutUserConfirmParam} putUserConfirmParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserConfirm: async (putUserConfirmParam: PutUserConfirmParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putUserConfirmParam' is not null or undefined
            assertParamExists('putUserConfirm', 'putUserConfirmParam', putUserConfirmParam)
            const localVarPath = `/api/public/putUserConfirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putUserConfirmParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutUserConfirmControllerApi - functional programming interface
 * @export
 */
export const PutUserConfirmControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutUserConfirmControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PutUserConfirmParam} putUserConfirmParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserConfirm(putUserConfirmParam: PutUserConfirmParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserConfirm(putUserConfirmParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutUserConfirmControllerApi - factory interface
 * @export
 */
export const PutUserConfirmControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutUserConfirmControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PutUserConfirmParam} putUserConfirmParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserConfirm(putUserConfirmParam: PutUserConfirmParam, options?: any): AxiosPromise<void> {
            return localVarFp.putUserConfirm(putUserConfirmParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutUserConfirmControllerApi - object-oriented interface
 * @export
 * @class PutUserConfirmControllerApi
 * @extends {BaseAPI}
 */
export class PutUserConfirmControllerApi extends BaseAPI {
    /**
     * 
     * @param {PutUserConfirmParam} putUserConfirmParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutUserConfirmControllerApi
     */
    public putUserConfirm(putUserConfirmParam: PutUserConfirmParam, options?: AxiosRequestConfig) {
        return PutUserConfirmControllerApiFp(this.configuration).putUserConfirm(putUserConfirmParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutUserControllerApi - axios parameter creator
 * @export
 */
export const PutUserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PutUserParam} putUserParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser: async (putUserParam: PutUserParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putUserParam' is not null or undefined
            assertParamExists('putUser', 'putUserParam', putUserParam)
            const localVarPath = `/api/public/putUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putUserParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutUserControllerApi - functional programming interface
 * @export
 */
export const PutUserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutUserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PutUserParam} putUserParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUser(putUserParam: PutUserParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUser(putUserParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutUserControllerApi - factory interface
 * @export
 */
export const PutUserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutUserControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PutUserParam} putUserParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUser(putUserParam: PutUserParam, options?: any): AxiosPromise<PutUserDto> {
            return localVarFp.putUser(putUserParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutUserControllerApi - object-oriented interface
 * @export
 * @class PutUserControllerApi
 * @extends {BaseAPI}
 */
export class PutUserControllerApi extends BaseAPI {
    /**
     * 
     * @param {PutUserParam} putUserParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutUserControllerApi
     */
    public putUser(putUserParam: PutUserParam, options?: AxiosRequestConfig) {
        return PutUserControllerApiFp(this.configuration).putUser(putUserParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PutUserInfoControllerApi - axios parameter creator
 * @export
 */
export const PutUserInfoControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PutUserInfoParam} putUserInfoParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserInfo: async (putUserInfoParam: PutUserInfoParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putUserInfoParam' is not null or undefined
            assertParamExists('putUserInfo', 'putUserInfoParam', putUserInfoParam)
            const localVarPath = `/api/putUserInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putUserInfoParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PutUserInfoControllerApi - functional programming interface
 * @export
 */
export const PutUserInfoControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PutUserInfoControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PutUserInfoParam} putUserInfoParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserInfo(putUserInfoParam: PutUserInfoParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutUserInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserInfo(putUserInfoParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PutUserInfoControllerApi - factory interface
 * @export
 */
export const PutUserInfoControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PutUserInfoControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {PutUserInfoParam} putUserInfoParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserInfo(putUserInfoParam: PutUserInfoParam, options?: any): AxiosPromise<PutUserInfoDto> {
            return localVarFp.putUserInfo(putUserInfoParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PutUserInfoControllerApi - object-oriented interface
 * @export
 * @class PutUserInfoControllerApi
 * @extends {BaseAPI}
 */
export class PutUserInfoControllerApi extends BaseAPI {
    /**
     * 
     * @param {PutUserInfoParam} putUserInfoParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PutUserInfoControllerApi
     */
    public putUserInfo(putUserInfoParam: PutUserInfoParam, options?: AxiosRequestConfig) {
        return PutUserInfoControllerApiFp(this.configuration).putUserInfo(putUserInfoParam, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ThreeDDataControllerApi - axios parameter creator
 * @export
 */
export const ThreeDDataControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary FRサーバが3Dモデルの計算を行った結果を更新します
         * @param {ThreeDCalcResultParam} threeDCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCalcResult: async (threeDCalcResultParam: ThreeDCalcResultParam, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'threeDCalcResultParam' is not null or undefined
            assertParamExists('putCalcResult', 'threeDCalcResultParam', threeDCalcResultParam)
            const localVarPath = `/internal/api/putCalcResult`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basicScheme required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(threeDCalcResultParam, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ThreeDDataControllerApi - functional programming interface
 * @export
 */
export const ThreeDDataControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ThreeDDataControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary FRサーバが3Dモデルの計算を行った結果を更新します
         * @param {ThreeDCalcResultParam} threeDCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCalcResult(threeDCalcResultParam: ThreeDCalcResultParam, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCalcResult(threeDCalcResultParam, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ThreeDDataControllerApi - factory interface
 * @export
 */
export const ThreeDDataControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ThreeDDataControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary FRサーバが3Dモデルの計算を行った結果を更新します
         * @param {ThreeDCalcResultParam} threeDCalcResultParam 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCalcResult(threeDCalcResultParam: ThreeDCalcResultParam, options?: any): AxiosPromise<string> {
            return localVarFp.putCalcResult(threeDCalcResultParam, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ThreeDDataControllerApi - object-oriented interface
 * @export
 * @class ThreeDDataControllerApi
 * @extends {BaseAPI}
 */
export class ThreeDDataControllerApi extends BaseAPI {
    /**
     * 
     * @summary FRサーバが3Dモデルの計算を行った結果を更新します
     * @param {ThreeDCalcResultParam} threeDCalcResultParam 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ThreeDDataControllerApi
     */
    public putCalcResult(threeDCalcResultParam: ThreeDCalcResultParam, options?: AxiosRequestConfig) {
        return ThreeDDataControllerApiFp(this.configuration).putCalcResult(threeDCalcResultParam, options).then((request) => request(this.axios, this.basePath));
    }
}


